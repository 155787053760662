import { Flex, Space, Typography } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/Button';

import { Placeholder } from './styled';

const { Title, Paragraph } = Typography;

interface IEmptyListPlaceholder {
  title: string;
  description: string | React.ReactElement;
  image: string;
  button?: {
    text: string;
    onClick: () => void;
  } | null;
}

const EmptyListPlaceholder = ({ title, description, image, button }: IEmptyListPlaceholder) => {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 950px)',
  });

  return (
    <Placeholder>
      <Space direction="vertical">
        <Flex wrap={isBigScreen ? 'nowrap' : 'wrap'}>
          <div>
            <Title level={2} data-testid="empty-placeholder:title">
              {title}
            </Title>
            <Paragraph style={{ whiteSpace: 'pre-wrap' }} data-testid="empty-placeholder:description">
              {description}
            </Paragraph>
          </div>
          <img src={image} alt="Placeholder" />
        </Flex>
        {button && (
          <Button onClick={button.onClick} type="primary" size="large" data-testid="empty-placeholder:button">
            {button.text}
          </Button>
        )}
      </Space>
    </Placeholder>
  );
};

export default EmptyListPlaceholder;
