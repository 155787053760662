import { Form, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import { Item, Input, TextArea } from 'components/Form';

const { Title, Paragraph } = Typography;

interface IRulesetDetailsProps {
  form: FormInstance;
  initialValues?: DetailsFormType;
}

type DetailsFormType = {
  name: string;
  description: string;
};

const INIT_DATA: DetailsFormType = {
  name: '',
  description: '',
};

const RulesetDetails = ({ form, initialValues }: IRulesetDetailsProps) => (
  <Form name="ruleset-details" form={form} initialValues={initialValues || INIT_DATA}>
    <Title level={3}>Ruleset Details</Title>
    <Paragraph>
      Let us know how your Geolocation Ruleset should be called. You can also add a description text (optional).
    </Paragraph>
    <Item
      label="Ruleset Name"
      name="name"
      rules={[{ required: true, message: 'Input required' }]}
      style={{ marginBottom: '24px' }}
    >
      <Input data-testid="input:ruleset-name" placeholder="Ruleset Name (e.g. Ruleset A)" />
    </Item>
    <Item colon={false} label="Description" name="description">
      <TextArea
        data-testid="input:ruleset-description"
        placeholder="Ruleset Description (e.g. Ruleset for example.com)"
        style={{ minHeight: '104px', maxHeight: '104px', resize: 'none' }}
      />
    </Item>
  </Form>
);

export default RulesetDetails;
