import styled, { css } from 'styled-components';

import { BankAccount, Card } from 'components/Icons';

export const TypeIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blue[8]};
  display: flex;
  align-items: center;
  justify-content: center;

  & svg path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const icon = css`
  width: 16px;
`;

export const CCIcon = styled(Card)`
  ${icon}
`;
export const BAIcon = styled(BankAccount)`
  ${icon}
`;
