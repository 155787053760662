import { CopyOutlined } from '@ant-design/icons';
import { CountryIsoCodeDictionary, StateIsoCodeDictionary } from '@usercentrics/i18n-iso3166-2';
import { Col, Row, Tooltip, Typography } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';

import {
  SearchSettingOutDto,
  SettingSubscriptionAssignment,
  SubscriptionType,
} from 'api/requests/generated/generated.schemas';
import { AppSubscription, Review as ReviewIcon, WebSubscription } from 'components/Icons';
import Tag from 'components/Tag';
import { getPlanName } from 'lib/helpers';
import { sort, sortByNameAndId } from 'lib/helpers/sort';
import { colors } from 'lib/theme/colors';

const countries: { [key: string]: string } = {
  ...CountryIsoCodeDictionary,
  ...StateIsoCodeDictionary,
};

const { Paragraph } = Typography;

export const generateConfigurationColumns = (
  newSettings: string[],
  {
    isCSA,
    isRulesetHidden,
  }: {
    isCSA: boolean;
    isRulesetHidden: boolean;
  },
) => {
  const columns = [
    {
      title: 'Configuration Name [ID]',
      key: 'name',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sortByNameAndId(a, b),
      ellipsis: true,
      width: '25%',
      showSorterTooltip: false,
      render: (record: SearchSettingOutDto) => {
        const text = [record.aliasName, `[${record.id}]`].filter((x) => x).join(' ');

        return (
          <Row justify="start" align="middle" gutter={[4, 0]}>
            <Col>
              {record?.subscription && record.subscription?.assignment !== SettingSubscriptionAssignment.past && (
                <Tag bordered $bolder color="blue-inverse" size="middle" data-testid={`framework:${record.id}`}>
                  {record.subscription.type === SubscriptionType.web ? (
                    <WebSubscription color="blue" />
                  ) : (
                    <AppSubscription />
                  )}
                </Tag>
              )}
              {record.reviewMode && <ReviewIcon data-testid={`setting[${record.id}]:review-icon`} />}
            </Col>

            <Col>
              <Tooltip title={text} placement="topLeft">
                <Paragraph
                  ellipsis
                  copyable={
                    isCSA
                      ? {
                          text: record.id,
                          icon: [<CopyOutlined style={{ color: colors.primaryText }} />],
                        }
                      : undefined
                  }
                  data-testid={`setting-id:${record.id}`}
                >
                  {text}
                </Paragraph>
              </Tooltip>
            </Col>
            <Col>
              {newSettings.includes(record.id) && (
                <Tag color="blue" data-testid={`new-label:${record.id}`} bordered>
                  New
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Domain / App-ID',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) =>
        sort(a.domains?.join() || '', b.domains?.join() || ''),
      showSorterTooltip: false,
      key: 'domainOrAppId',
      ellipsis: true,
      render: (record: SearchSettingOutDto) => {
        const domains = record.domains?.join(', ');
        const appIds = record.appIds?.join(', ');
        //@ts-ignore
        const pubishedApps = record.publishedApps?.map((app) => app.bundleId).join(', ');

        const text = domains || pubishedApps || appIds;

        return (
          <Tooltip title={text} placement="topLeft">
            <span data-testid={`domain:${record.id}`}>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Data Controller',
      showSorterTooltip: false,
      dataIndex: 'dataController',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sort(a.dataController, b.dataController),
      key: 'dataController',
      ellipsis: true,
      render: (dataController: string, record: SearchSettingOutDto) => (
        <Tooltip title={dataController} placement="topLeft">
          <span data-testid={`data-controller:${record.id}`}>{dataController}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Framework',
      showSorterTooltip: false,
      dataIndex: 'legalFramework',
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) => sort(a.legalFramework, b.legalFramework),
      key: 'legalFramework',
      ellipsis: true,
      render: (legalFramework: string, record: SearchSettingOutDto) => (
        <Tooltip title={legalFramework}>
          <Tag bordered $bolder color="blue-inverse" size="normal" data-testid={`framework:${record.id}`}>
            {legalFramework}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Ruleset',
      dataIndex: 'ruleSets',
      key: 'ruleset',
      hidden: isRulesetHidden,
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) =>
        sort(a.ruleSets?.join() || '', b.ruleSets?.join() || ''),
      render: (rulesets: { name: string; id: string }[], record: SearchSettingOutDto) => {
        return (
          <Paragraph
            ellipsis={{
              tooltip: { placement: 'topLeft' },
              expandable: rulesets.length > 1,
              rows: 3,
              symbol: <div>Show more</div>,
              onExpand: (e) => e.stopPropagation(),
            }}
            data-testid={`rulesets:${record.id}`}
          >
            {rulesets.map((r) => (
              <Link
                to={{
                  pathname: `/company/${record.parentId}/geo-rulesets`,
                  hash: `#${r.id}`,
                }}
                onClick={(e) => e.stopPropagation()}
                key={r.id}
              >
                {r?.name} <br />
              </Link>
            ))}
          </Paragraph>
        );
      },
    },
    {
      title: 'Regions',
      dataIndex: 'regions',
      key: 'regions',
      hidden: isRulesetHidden,
      ellipsis: true,
      sorter: (a: SearchSettingOutDto, b: SearchSettingOutDto) =>
        sort(a.regions?.join() || '', b.regions?.join() || ''),
      render: (locations: string[], record: SearchSettingOutDto) => {
        const updatedRegions = locations.map((location) => countries[location] || location).join(', ');
        return (
          <Tooltip title={updatedRegions} placement="top">
            <span data-testid={`regions:${record.id}`}>{updatedRegions}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Subscription',
      key: 'subscription',
      ellipsis: {
        showTitle: true,
      },
      hidden: !isCSA,
      render: (record: SearchSettingOutDto) => {
        const text = getPlanName(record.subscription);

        return record?.subscription?.assignment !== SettingSubscriptionAssignment.past ? (
          <Tooltip title={text}>
            <span data-testid={`subscription:${record.id}`}>{text}</span>
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ].filter((col) => !col.hidden);

  return columns.filter(({ key }) => (!isCSA ? key !== 'createdAt' : true));
};
