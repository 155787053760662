import { Layout as AntLayout, Flex } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { pr, flexBetween } from 'shared-components/styled';

const { Header: AntHeader } = AntLayout;

interface IProps {
  $hideInfo?: boolean;
  displaySupportAccess?: boolean;
}

export const Header = styled(AntHeader)<IProps>`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
  max-height: 80rem;
  position: fixed;
  z-index: 10;
  ${flexBetween}
  align-items: center;

  & > img {
    display: flex;
    flex: 0 0 13rem;
    width: 178px;
    height: 24px;
    align-items: center;
    box-sizing: content-box;
    border-right: ${(props) => (props.$hideInfo ? 'none' : `1px solid ${props.theme.colors.gray[16]}`)};
    padding: 0 2.5rem 0 0;
    margin-right: 24px;
  }
`;

export const HeaderItem = styled(NavLink)`
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[80]};
  display: flex;
  align-items: center;

  svg {
    transform: rotate(180deg);
    margin-right: 8px;

    & path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  &.active,
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SelectedCompany = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  ${pr}
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Delimiter = styled.i`
  display: inline-block;
  margin-right: 10px;
  transform: rotate(90deg);
  color: ${({ theme }) => theme.colors.gray[80]};
`;

export const SupportAccess = styled.div`
  margin-left: auto;
  align-items: center;
  justify-content: end;
  line-height: 1;
  display: flex;
`;

export const SupportAccessLabel = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryText};
  margin-right: 14px;
`;

export const UserWrapper = styled(Flex)`
  padding: 0 16px;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.colors.gray[16]};
  height: 100%;

  svg {
    width: 24px;
    height: 24px;

    & > g rect:hover {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  .anticon {
    border-radius: 100px;
    padding: 3px;
    vertical-align: middle;
  }

  & .ant-dropdown-trigger {
    margin: 0 5px;
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  padding: 0 16px 12px 16px;

  & #user-placeholder {
    width: 36px;
    height: 36px;
    margin: 16px 16px 0 0;
  }
`;

export const UserName = styled.span`
  font-size: 1rem;
  font-weight: 500;
`;

export const DateLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
`;

export const DropdownItem = styled.div`
  margin: 5px 0;
`;
