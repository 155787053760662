import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 15.6875rem;
  background: ${({ theme }) => theme.colors.gray[4]};
  padding: 16px;
  position: fixed;
  min-height: calc(100vh - 64px);
  z-index: 9;
`;

export const Item = styled(NavLink)`
  padding: 10px 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.blue[8]};
    border-radius: 8px;
    font-weight: 600;
    .ant-typography {
      color: ${({ theme }) => theme.colors.primary};
    }
    & svg,
    & svg g path,
    & svg #user-management,
    #account {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;
