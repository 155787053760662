/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import { Col, Divider, Flex, Form, FormInstance, Row, Space } from 'antd';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useNavigate } from 'react-router-dom';

import { createSetting, getMyAccounts } from 'api/requests';
import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import { createCustomerAndSubscription } from 'api/requests/onboarding';
import { postSurveyAnswers } from 'api/requests/survey';
import Button from 'components/Button';
import DotStepper from 'components/DotStepper';
import { Layout } from 'components/Layout/styled';
import { Currency, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { ISurveyRequestAnswer } from 'interfaces/responses';
import { OnboardingSteps } from 'interfaces/steps';
import { ONBOARDING_PRICE_PLAN, ONBOARDING_TYPE, SURVEY_ID, USER_AUTH0_PARAMETERS } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { trackOnboardingSurveySubmitEvent } from 'lib/helpers/analytics';
import { clearTrialCookies } from 'lib/helpers/clearTrialCookies';
import { useMessage } from 'lib/hooks';

import { Header, Sidebar } from './Layout';
import { ErrorState, LoadingState } from './Status';
import Details from './Steps/Details';
import FrameworkSelector from './Steps/FrameworkSelector/FrameworkSelector';
import Implementation from './Steps/Implementation';
import Survey from '../Survey';

const { Content } = Layout;

export interface ITrialDetailsForm {
  companyName: string;
  domain: string;
  currency: Currency;
  address: {
    country: string;
    city: string;
    street: string;
    zip: string;
  };
  confirmation?: boolean;
  type?: SubscriptionType;
}

const Onboarding = () => {
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState<OnboardingSteps>(OnboardingSteps.AccountDetails);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState<{ companyId: string; settingId: string } | null>(null);
  const [isError, setIsError] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(SubscriptionType.web);
  const message = useMessage();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [detailsForm] = Form.useForm<ITrialDetailsForm>();
  const [surveyForm] = Form.useForm<FormInstance>();
  const [configurationForm] = Form.useForm<{ configurationName: string; framework: string }>();

  useEffect(() => {
    if (user?.one_pricing_plan) {
      setSubscriptionType(SubscriptionType.web);
    } else {
      setSubscriptionType(user?.[USER_AUTH0_PARAMETERS.uc_subscription_type] || SubscriptionType.web);
    }

    if (!state?.canBeCreated && !state?.hasAnySettings) {
      setCurrentStep(OnboardingSteps.Configuration);
      return;
    }
  }, []);

  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const prepareAccount = async () => {
    try {
      const accountDetailsValues = detailsForm.getFieldsValue(true);

      const couponId =
        (user?.[USER_AUTH0_PARAMETERS.uc_onboarding] !== ONBOARDING_TYPE.STANDARD && user?.uc_coupon) || undefined;

      const { companyId, customerId } = await createCustomerAndSubscription({
        domain: accountDetailsValues.domain,
        companyName: accountDetailsValues.companyName,
        firstName: user?.given_name || user?.name || user?.nickname || '',
        lastName: user?.family_name || user?.middle_name || '',
        onboardingType: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        currency: accountDetailsValues.currency,
        ...accountDetailsValues.address,
        type: subscriptionType,
        couponId,
        leadSource: Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || undefined,
        ...(user?.[USER_AUTH0_PARAMETERS.uc_onboarding] === ONBOARDING_TYPE.SUMMER_2024
          ? { pricePlan: ONBOARDING_PRICE_PLAN.SUMMER_2024 }
          : { pricePlan: user?.[USER_AUTH0_PARAMETERS.one_pricing_plan] }),
      });

      /**
       * Tracking events
       */
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.ONBOARDING_TRIAL_STARTED,
          [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.PLATFORM]: subscriptionType,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
          [TrackingVariables.COMPANY_ID]: companyId,
        },
      });
      trackEvent(TrackingEvents.ONBOARDING_TRIAL_STARTED, {
        [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        [TrackingVariables.PLATFORM]: subscriptionType,
        [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
          Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
        ),
        [TrackingVariables.COMPANY_ID]: companyId,
      });
      /**
       * =====
       */

      return { companyId, customerId };
    } catch (error) {
      let messageText = error;
      if (error.statusCode === 400) {
        messageText = error.response?.data.error?.msg;
        message.error(messageText);
      } else if (typeof error === 'string') {
        message.error(error);
      }
      TagManager.dataLayer({
        dataLayer: {
          event: TrackingEvents.ONBOARDING_TRIAL_START_FAILED,
          [TrackingVariables.ERROR]: messageText,
        },
      });
      trackEvent(TrackingEvents.ONBOARDING_TRIAL_START_FAILED, {
        [TrackingVariables.ERROR]: messageText,
      });
      setCurrentStep(OnboardingSteps.AccountDetails);

      return { companyId: '', customerId: '' };
    }
  };

  const prepareSetting = async (companyId: string) => {
    try {
      const configurationValues = configurationForm.getFieldsValue(true);
      const domain = detailsForm.getFieldValue('domain');

      setIsLoading(true);

      return createSetting({
        companyId,
        domain: domain || '',
        dataController: '',
        legalFramework: configurationValues.framework,
        name: configurationValues.name,
        type: subscriptionType,
      });
    } catch (error) {
      throw error;
    }
  };

  const submitSurvey = async (surveyId: string, ucBillingAccount?: string): Promise<void> => {
    const answers = Object.entries(surveyForm.getFieldsValue(true)).map(([questionId, value]) => ({
      questionId,
      value,
    })) as ISurveyRequestAnswer[];

    try {
      await postSurveyAnswers(surveyId, { answers, references: { ucBillingAccount } });
      trackOnboardingSurveySubmitEvent(answers);
    } catch (err) {
      setIsError(true);
      console.error(err);
    }
  };

  const onCreateAccount = useCallback(async () => {
    /**
     * Track events
     */

    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.ONBOARDING_SETUP_COMPLETED,
        [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
        [TrackingVariables.PLATFORM]: subscriptionType,
        [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
          Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
        ),
      },
    });
    trackEvent(TrackingEvents.ONBOARDING_SETUP_COMPLETED, {
      [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
      [TrackingVariables.PLATFORM]: subscriptionType,
      [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
        Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
      ),
    });

    /**
     * ====
     */

    try {
      let companyId;
      let customerId: string;

      setIsLoading(true);

      if (state?.canBeCreated) {
        const result = await prepareAccount();
        companyId = result.companyId;
        customerId = result.customerId;

        if (!companyId || !customerId) {
          throw new Error("The account hasn't been prepared");
        }
      } else {
        const { body: accounts } = await getMyAccounts();
        companyId = accounts[0]?.companies[0].id;
        customerId = accounts[0]?.chargebeeCustomerId;
      }

      const settingId = (await prepareSetting(companyId)).body.settingId;

      await submitSurvey(SURVEY_ID, customerId);

      if (!settingId) {
        throw new Error("The configuration hasn't been prepared. Please try again");
      }

      setCustomerData({ settingId, companyId });
      clearTrialCookies();
      handleNext();
    } catch (error) {
      if (error?.response) {
        message.error(error.response?.data.error?.msg);
      }
    } finally {
      setIsLoading(false);
    }
  }, [detailsForm, configurationForm, setIsLoading, setIsError]);

  const onFinish = () => {
    if (customerData?.companyId) {
      navigate(`/company/${customerData?.companyId}/configurations`);
    } else {
      navigate('/companies');
    }
  };

  const steps = [
    {
      title: 'Setup your account',
      primaryButton: 'Next',
      sidebar: {
        label: 'Get started',
        title: 'Welcome to Usercentrics',
        description:
          'To get you started and enable you to have a seamless experience please fill out the contact information',
      },
      content: <Details subscriptionType={subscriptionType} form={detailsForm} setDisabled={setDisabled} />,
      onClick: handleNext,
    },
    {
      title: 'Create Configuration',
      primaryButton: 'Create Account',
      sidebar: {
        label: 'Set up your CMP',
        title: 'Let’s create a configuration',
        description:
          'A configuration is a consent banner that is tailored to a specific legal framework and can be flexibly adapted to your legal requirements.',
      },
      content: <FrameworkSelector form={configurationForm} setDisabled={setDisabled} />,
      onClick: onCreateAccount,
    },
    {
      title: 'Implement Configuration',
      primaryButton: 'Finish',
      sidebar: {
        label: 'Set up your CMP',
        title: 'Time to implement your configuration',
        description: 'Your consent banner is ready to be implemented.',
      },
      content: <Implementation settingId={customerData?.settingId || ''} />,
      onClick: onFinish,
    },
  ];

  const titles = steps.map((step) => step.title);
  const currentStepData = steps[currentStep];
  const isAllowedToGoBack = currentStep !== 0 && currentStep !== OnboardingSteps.Implementation && state?.canBeCreated;

  return (
    <Flex justify="center">
      <Layout style={{ minHeight: '100vh', maxWidth: '1440px' }}>
        <Sidebar {...currentStepData.sidebar} />
        <Layout>
          <Header />
          <Content style={{ margin: '4px 48px 48px' }}>
            {isError ? (
              <ErrorState />
            ) : isLoading ? (
              <LoadingState />
            ) : (
              <>
                <Row justify="space-between" align="middle">
                  <Col>
                    <DotStepper steps={titles} activeStep={currentStep} />
                  </Col>
                  <Col>
                    <Space size={16}>
                      {isAllowedToGoBack && (
                        <Button className="onboarding" onClick={handleBack}>
                          Back
                        </Button>
                      )}
                      {state?.hasAnySettings &&
                        state?.canBeCreated &&
                        currentStep !== OnboardingSteps.Implementation && (
                          <Button
                            className="onboarding"
                            onClick={() => {
                              clearTrialCookies();
                              navigate('/');
                            }}
                          >
                            Cancel new trial
                          </Button>
                        )}
                      <Button
                        className="onboarding"
                        type="primary"
                        onClick={currentStepData.onClick}
                        disabled={disabled}
                      >
                        {currentStepData.primaryButton}
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: '16px 0' }} />
                <Content className="scroll" style={{ height: 'calc(100vh - 250px)' }}>
                  {currentStepData.content}
                </Content>
              </>
            )}
          </Content>
        </Layout>
        <Survey onSubmit={submitSurvey} surveyId={SURVEY_ID} form={surveyForm} />
      </Layout>
    </Flex>
  );
};

export default Onboarding;
