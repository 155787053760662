import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Footer from 'components/Footer';
import Header from 'components/Header';
import UpgradePlan from 'components/Modal/UpgradePlan';
import { usePrivileges, useUpgradePlan, useTrial } from 'lib/hooks';

import { Layout, ContentContainer, Content, Container } from './styled';

const BillingLayout = () => {
  const { subscription, setPlanToUpgrade } = useUpgradePlan();
  const { hasAccounts, isLoading } = usePrivileges();
  const { expiredTrial } = useTrial();
  const navigate = useNavigate();

  useEffect(() => {
    if (expiredTrial || (!hasAccounts && !isLoading)) {
      navigate('/');
    }
  }, [expiredTrial]);

  return (
    <Layout>
      {subscription && <UpgradePlan open onClose={() => setPlanToUpgrade(null)} />}
      <Header isBillingHeader />
      <Container>
        <ContentContainer>
          <Content>
            <Outlet />
          </Content>
          <Footer />
        </ContentContainer>
      </Container>
    </Layout>
  );
};

export default BillingLayout;
