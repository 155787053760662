import { Flex, Form, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { updateBillingAccountBillingInfo } from 'api/requests';
import { useAccountControllerGetAccountDetails } from 'api/requests/generated/account/account';
import AddressItem, { type Address } from 'components/AddressItem';
import Button from 'components/Button';
import Card from 'components/Card';
import { Input, Item } from 'components/Form';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { useMessage, useUpgradePlan } from 'lib/hooks';

const { useWatch } = Form;

interface IAccountDetailsForm extends Address {
  name: string;
}

interface IAccountDetailsProps {
  subscription: IUpgradeSubscription;
  handleNext: () => void;
}

const AccountDetails = ({ subscription, handleNext }: IAccountDetailsProps) => {
  const [isUpdatingLoading, setIsUpdatingLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const message = useMessage();
  const { setPlanToUpgrade } = useUpgradePlan();

  const [detailsForm] = Form.useForm<IAccountDetailsForm>();
  const detailsValues = useWatch([], { form: detailsForm });
  const {
    data,
    isLoading: isAccountDetailsLoading,
    mutate,
  } = useAccountControllerGetAccountDetails(subscription.billingCustomerId);
  const account = data?.body;

  useEffect(() => {
    detailsForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsFormValid(true);
      },
      () => {
        setIsFormValid(false);
      },
    );
  }, [detailsValues]);

  if (isAccountDetailsLoading) {
    return (
      <Card
        styles={{
          body: {
            height: 270,
            alignContent: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Spin style={{ width: '100%' }} />
      </Card>
    );
  }

  const onNextClick = async (values: IAccountDetailsForm) => {
    try {
      setIsUpdatingLoading(true);

      if (detailsForm.isFieldsTouched() && values && account) {
        detailsForm.setFieldsValue(values);
        await updateBillingAccountBillingInfo(account.id, { name: values.name, ...values.address });
        const updatedAccount = (await mutate())?.body;

        setPlanToUpgrade({ ...subscription, country: updatedAccount?.country || subscription.country });
      }

      handleNext();
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    } finally {
      setIsUpdatingLoading(false);
    }
  };

  return (
    <Form
      name="company-details"
      form={detailsForm}
      onFinish={onNextClick}
      initialValues={{
        name: account?.name,
        address: {
          city: account?.city,
          country: account?.country,
          street: account?.street,
          zip: account?.postcode,
        },
      }}
    >
      <Flex vertical gap={24}>
        <Card>
          <Flex vertical gap={8}>
            <Item
              label="Company name"
              colon={false}
              name="name"
              requiredMark={false}
              rules={[{ required: true, message: 'Company name is required' }]}
            >
              <Input placeholder="Company name" />
            </Item>
            <AddressItem<IAccountDetailsForm> form={detailsForm} />
          </Flex>
        </Card>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isAccountDetailsLoading || !isFormValid}
          loading={isUpdatingLoading}
          block
          style={{ padding: 12, marginBottom: 20 }}
        >
          Next
        </Button>
      </Flex>
    </Form>
  );
};

export default AccountDetails;
