import { PlusOutlined as PlusIcon } from '@ant-design/icons';
import { Flex } from 'antd';
import { useState, useMemo } from 'react';

import { useAccountAccess } from 'api/requests';
import {
  AccountOutDto,
  ListPermissionBillingAccountsOutDto,
  PermissionBillingAccountOutDtoPermission as Permission,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Card from 'components/Card';
import Collapse from 'components/Collapse';
import { Person as PersonIcon, Card as CardIcon } from 'components/Icons';
import Tag from 'components/Tag';
import { Text } from 'components/Typography';
import { ACCOUNT_BILLING_ACCESS, getFeatureFlags } from 'lib/helpers/featureFlags';

import ManageAccessModal from './ManageAccessModal';
import { Account, Login } from './styled';

const order = [Permission.ADMIN, Permission.READ] as const;
const labels = {
  [Permission.ADMIN]: 'Account Admin',
  [Permission.READ]: 'Account User',
};

export const getLabel = (user: ListPermissionBillingAccountsOutDto) => {
  if (user.isOwner) return 'Account Owner';
  return labels[user.permission as (typeof order)[number]];
};

const sortAccounts = (accounts: ListPermissionBillingAccountsOutDto[]) =>
  accounts.sort((a, b) => {
    if (a.isOwner) return -1;

    const orderComparison =
      order.indexOf(a.permission as (typeof order)[number]) - order.indexOf(b.permission as (typeof order)[number]);
    if (orderComparison === 0) {
      return a.login.localeCompare(b.login);
    }
    return orderComparison;
  });

const ffs = getFeatureFlags();
const ffEnabled = ffs.includes(ACCOUNT_BILLING_ACCESS);

const AccountAccess = ({ account }: { account: AccountOutDto }) => {
  const [showModal, setShowModal] = useState(false);
  const { data, mutate } = useAccountAccess(ffEnabled ? account.id : '');

  const accounts = useMemo(() => sortAccounts(data?.body || []), [data]);
  const isReadOnly = account.permission === Permission.READ;

  return (
    <>
      <Collapse
        expandIconPosition="end"
        defaultActiveKey="account"
        items={[
          {
            key: 'account',
            label: (
              <Flex align="center" justify="space-between">
                <Text size="md" strong>
                  Account Access
                </Text>
                {!isReadOnly && ffEnabled && (
                  <Button icon={<PlusIcon />} onClick={() => setShowModal(true)}>
                    Manage access
                  </Button>
                )}
              </Flex>
            ),
            children: (
              <Card bordered={false}>
                <Flex vertical gap={8}>
                  {ffEnabled &&
                    accounts.map((a) => (
                      <Account justify="space-between" align="center" key={a.login}>
                        <Login align="center" gap={12}>
                          <PersonIcon />
                          {a.login}
                        </Login>
                        <Tag $bold size="normal" color="default">
                          <Flex gap={8} align="center">
                            <CardIcon />
                            {getLabel(a)}
                          </Flex>
                        </Tag>
                      </Account>
                    ))}
                  {!ffEnabled && (
                    <Account justify="space-between" align="center">
                      <Login align="center" gap={12}>
                        <PersonIcon />
                        {account.user.email}
                      </Login>
                      <Tag $bold size="normal" color="default">
                        <Flex gap={8} align="center">
                          <CardIcon />
                          Account Owner
                        </Flex>
                      </Tag>
                    </Account>
                  )}
                </Flex>
              </Card>
            ),
          },
        ]}
      />
      <ManageAccessModal
        open={showModal}
        account={account}
        accounts={accounts}
        onClose={() => setShowModal(false)}
        onUpdateList={mutate}
      />
    </>
  );
};

export default AccountAccess;
