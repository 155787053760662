import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = ({ color }: { color?: 'gradient-blue' | string }) => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_670_3690)">
      <path
        d="M3.11331 12C2.87998 12 2.69998 11.7933 2.73331 11.56L3.33331 7.33333H0.999981C0.413314 7.33333 0.779981 6.83333 0.793314 6.81333C1.63331 5.32667 2.89331 3.12667 4.55998 0.193333C4.62665 0.0733333 4.75998 0 4.89331 0C5.12665 0 5.30665 0.206667 5.27331 0.44L4.67331 4.66667H7.01331C7.27998 4.66667 7.42665 4.79333 7.27998 5.10667C5.08665 8.93333 3.81331 11.1667 3.44665 11.8067C3.37998 11.9267 3.25331 12 3.11331 12Z"
        fill={color === 'gradient-blue' ? 'url(#paint0_linear_670_3690)' : color || '#303030'}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_670_3690"
        x1="11"
        y1="11"
        x2="30.0211"
        y2="11.0223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
