import { Flex, Space } from 'antd';

import Tag from 'components/Tag';
import { Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { DotsContainer, Dot } from './styled';

interface IStepper {
  steps: string[];
  activeStep: number;
  tagText?: string;
}

const DotStepper = ({ activeStep, steps, tagText }: IStepper) => {
  const getColorByStep = (step: number) => {
    if (step === activeStep) {
      return colors.primary;
    }

    if (step > activeStep) {
      return colors.gray[24];
    }

    if (step < activeStep) {
      return colors.green[100];
    }

    return colors.primary;
  };

  return (
    <Flex vertical gap={12}>
      <DotsContainer>
        {steps.map((step, index) => (
          <Dot key={step} color={getColorByStep(index)} />
        ))}
      </DotsContainer>
      <Title $noMargin level={1}>
        <Space size={16}>
          {steps[activeStep]}
          {Boolean(tagText) && (
            <Flex>
              <Tag size="normal" color="blue-inverse" $bold={true} bordered={false} style={{ borderRadius: '1rem' }}>
                {tagText}
              </Tag>
            </Flex>
          )}
        </Space>
      </Title>
    </Flex>
  );
};

export default DotStepper;
