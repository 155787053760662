import React, { useState } from 'react';

import { isDefined } from 'lib/helpers';

import Modal, { IButtonProps, IModalProps } from './Modal';

interface IStep {
  content: React.ReactNode;
  title?: string | React.ReactNode;
  handleNext?: <T>() => Promise<T | void> | void;
  primaryButton?: IButtonProps | null;
  secondaryButton?: IButtonProps | null;
}

interface IStepModalProps extends IModalProps {
  steps?: (IStep | undefined)[];
  onSubmit?: () => void;
}

const StepModal = ({ steps, okText, cancelText, onClose, onSubmit, width, title, ...props }: IStepModalProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  if (!steps?.length) {
    return null;
  }

  const filteredSteps = steps.filter(isDefined);

  const step = filteredSteps[activeStep];

  const isLastStep = activeStep === filteredSteps.length - 1;
  const isFirstStep = activeStep === 0;

  const primaryText = isLastStep ? okText || 'Submit' : 'Next';
  const secondaryText = isFirstStep ? cancelText || 'Cancel' : 'Back';

  const handleNext = () => {
    if (!isLastStep) {
      setActiveStep(activeStep + 1);
    } else {
      if (onSubmit) onSubmit();
      onCancel();
    }
  };

  const handleBack = () => {
    if (isFirstStep) {
      onClose();
      return;
    }

    setActiveStep(activeStep - 1);
  };

  const onOk = async () => {
    if (step.handleNext) {
      try {
        setConfirmLoading(true);
        await step.handleNext();
      } finally {
        setConfirmLoading(false);
      }
    }

    handleNext();
  };

  const onCancel = () => {
    setActiveStep(0);
    onClose();
  };

  const primary = {
    ...props.primaryButton,
    ...step.primaryButton,
    label: step.primaryButton?.label || (primaryText as string),
    onClick: onOk,
    'data-testid': 'button:next',
    loading: confirmLoading,
  };

  const secondary = {
    ...props.secondaryButton,
    ...step.secondaryButton,
    label: step.secondaryButton?.label || (secondaryText as string),
    onClick: handleBack,
    'data-testid': isFirstStep ? 'button:cancel' : 'button:back',
  };

  return (
    <Modal
      title={title || step.title}
      onClose={onClose}
      onCancel={onCancel}
      primaryButton={primary}
      secondaryButton={secondary}
      width={width || 'auto'}
      {...props}
    >
      {step.content}
    </Modal>
  );
};

export default StepModal;
