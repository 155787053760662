import { Layout, Flex } from 'antd';

import Logo from 'components/Logo';
import { Title } from 'components/Typography';
import { colors } from 'lib/theme/colors';

import { OnboardingBlock, OnboardingParagraph } from './styled';

const { Sider } = Layout;

interface IOnboardingSideBarProps {
  title: string;
  description: string;
  label: string;
  tagText?: string;
}

const OnboardingSideBar = ({ title, description, label }: IOnboardingSideBarProps) => (
  <Sider width="540px">
    <OnboardingBlock align="start" vertical>
      <Logo width={231} />
      <Flex style={{ height: '100%' }} justify="center" vertical>
        <Title level={3} style={{ color: colors.primary }}>
          {label}
        </Title>
        <Title style={{ fontSize: '3.25rem', margin: '12px 0' }}>{title}</Title>
        <OnboardingParagraph> {description}</OnboardingParagraph>
      </Flex>
    </OnboardingBlock>
  </Sider>
);

export default OnboardingSideBar;
