import { ThemeConfig } from 'antd';

import { colors } from './colors';
import { fontSizes } from './fontSizes';

export const theme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
  },
  components: {
    Tag: {
      defaultBg: colors.gray[8],
    },
    Alert: {
      defaultPadding: 16,
      colorWarning: colors.yellow[100],
      colorWarningBg: colors.yellow[8],
      lineWidth: 0,
      withDescriptionIconSize: 20,
    },
    Layout: {
      headerBg: colors.white,
      headerPadding: '12px 0',
    },
    Button: {
      fontSize: 14,
      fontSizeLG: 14,
      borderRadius: 4,
      borderRadiusLG: 4,
      colorLink: colors.primary,
      colorLinkHover: colors.primary,
    },
    Collapse: {
      headerPadding: '20px 20px 12px 20px',
      contentPadding: 0,
      headerBg: colors.white,
    },
    Typography: {
      ...fontSizes,
      colorText: colors.primaryText,
      colorTextDescription: colors.secondary,
      colorLink: colors.primary,
      colorLinkHover: colors.primary,
      colorSuccess: colors.green[100],
    },
    Switch: {
      handleSize: 16,
      trackMinWidth: 40,
      trackHeight: 20,
      colorTextQuaternary: colors.gray[80],
    },
  },
};
