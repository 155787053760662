import { useEffect } from 'react';

import useScript from './useScript';

const useChargebee = () => {
  if (process.env.REACT_APP_E2E_TESTS === '1') {
    return null;
  }

  const status = useScript('https://js.chargebee.com/v2/chargebee.js');
  const { Chargebee } = window;

  useEffect(() => {
    if (status) {
      if (Chargebee && !Chargebee.inited) {
        Chargebee.init({
          site: process.env.REACT_APP_CHARGEBEE_SITE,
          publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY,
        });
      }
    }
  }, [status]);

  return { isInited: Chargebee?.inited };
};

export default useChargebee;
