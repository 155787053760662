import { Flex, Col } from 'antd';

import {
  AccountOutDto,
  SubscriptionDto,
  PaymentSourceType,
  PaymentSourceDto,
  SubscriptionType as ISubscriptionType,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import Card from 'components/Card';
import { CreditCardOff, Lightning as LightningIcon } from 'components/Icons';
import SubscriptionType from 'components/SubscriptionType';
import Tag from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { Currency, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { numberWithCommas, getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { useUpgradePlan } from 'lib/hooks';
import { colors } from 'lib/theme/colors';
import { Counter } from 'shared-components';

import { TypeIcon, CCIcon, BAIcon } from './styled';

export const formatDate = (date: string | null) => new Date(date as string).toLocaleDateString('en-GB');

export const getSubscriptionDescription = (subscription: SubscriptionDto) => {
  switch (subscription.status) {
    case 'active':
      return `Active since ${formatDate(subscription.startedAt)}`;
    case 'non_renewing':
      return `Cancels on ${formatDate(subscription.finishedAt)}`;
    case 'cancelled':
      return `Cancelled on ${formatDate(subscription.finishedAt)}`;
    case 'future':
      if (subscription.trialStartedAt) {
        return `Trials starts on ${formatDate(subscription.trialStartedAt)}`;
      } else {
        return `Activates on ${formatDate(subscription.startedAt)}`;
      }
    case 'in_trial':
      return `Trials ends on ${formatDate(subscription.trialFinishedAt)}`;
  }
};

export const getSubscriptionStatusName = (status: SubscriptionStatus) => {
  switch (status) {
    case 'in_trial':
      return 'In Trial';
    case 'non_renewing':
      return 'Non Renewing';
    default:
      return status.toUpperCaseFirst();
  }
};

export const getColorByStatus = (status: SubscriptionStatus) => {
  switch (status) {
    case 'cancelled':
    case 'non_renewing':
      return 'red';
    case 'active':
      return 'green';
    case 'future':
      return 'default';
    case 'in_trial':
      return 'gold';
    default:
      return 'default';
  }
};

interface ISubscriptionCard {
  subscription: SubscriptionDto;
  paymentMethodExist?: boolean;
  account: AccountOutDto;
  onClick?: () => void;
  isReadOnly?: boolean;
}

const renderPaymentSource = (ps?: PaymentSourceDto) => {
  if (!ps)
    return (
      <Flex align="center" gap={6}>
        <CreditCardOff />
        <Text style={{ color: colors.gray[80] }}>not added</Text>
      </Flex>
    );

  if (ps.type === PaymentSourceType.card) {
    return (
      <Flex align="center" gap={4}>
        <CCIcon />
        Credit Card
      </Flex>
    );
  }
  if (ps.type === PaymentSourceType.direct_debit) {
    return (
      <Flex align="center" gap={4}>
        <BAIcon />
        Bank Account
      </Flex>
    );
  }
};

const SubscriptionCard = ({ subscription, account, onClick, isReadOnly }: ISubscriptionCard) => {
  const { setPlanToUpgrade } = useUpgradePlan();
  const showUpgradeButton = subscription.showUpgradeButton && subscription.status !== 'cancelled';
  const primaryPaymentMethod = account.paymentSources.find((ps) => ps.id === account.primaryPaymentSourceId);

  const onUpgrade = () => {
    /**
     * Tracking events
     */
    trackEvent(TrackingEvents.CTA_TRIAL_BAR_UPGRADE, {
      [TrackingVariables.SOURCE_PAGE]: 'Account',
    });
    /**
     * ====
     */

    setPlanToUpgrade({
      ...subscription,
      accountName: account.name,
      billingCustomerId: account.id,
      vatNumber: account.vatNumber as string,
      country: account.country,
    });
  };

  return (
    <Card
      styles={{
        body: { padding: 16 },
        header: { padding: 16 },
      }}
      borderedFooter
      footer={
        <Flex justify="space-between">
          <Flex align="center" gap={8}>
            Configurations
            <Counter
              count={subscription.actualSettingsCount}
              data-testid="counter:settings-on-subscriptions"
              showZero
            />
          </Flex>
          {!isReadOnly && onClick && (
            <Button
              onClick={onClick}
              disabled={subscription.status === 'cancelled'}
              data-testid="button:assign-configurations"
            >
              Assign Configurations
            </Button>
          )}
        </Flex>
      }
      title={
        <Flex justify="space-between" align="center">
          <Flex justify="space-between" align="center">
            <TypeIcon>
              <SubscriptionType type={subscription.type} />
            </TypeIcon>
            <Title
              style={{ margin: '0 0 0 12px', maxWidth: '200px' }}
              weight={500}
              level={4}
              ellipsis={{ tooltip: getPlanName(subscription) }}
            >
              {getPlanName(subscription)}
            </Title>
          </Flex>
          <Tag
            size="middle"
            color={getColorByStatus(subscription.status)}
            style={{ height: '28px', lineHeight: '25px', padding: '0 8px' }}
          >
            {getSubscriptionStatusName(subscription.status)}
          </Tag>
        </Flex>
      }
    >
      <Flex vertical>
        <Flex justify="space-between">
          <Col span={10}>
            <Text data-testid={`${subscription.id}:subscription-status`} weight={500}>
              Status
            </Text>
          </Col>
          <Col span={14}>
            <Text>{getSubscriptionDescription(subscription)}</Text>
          </Col>
        </Flex>
        {subscription.nextBillingAt && (
          <Flex justify="space-between">
            <Col span={10}>
              <Text data-testid={`${subscription.id}:subscription-status`} weight={500}>
                Billing
              </Text>
            </Col>
            <Col span={14}>
              <Text>Next Billing on {formatDate(subscription.nextBillingAt)}</Text>
            </Col>
          </Flex>
        )}
        <Flex justify="space-between">
          <Col span={10}>
            <Text data-testid={`${subscription.id}:subscription-info`} weight={500}>
              Subscription-ID
            </Text>
          </Col>
          <Col span={14}>
            <Text>{subscription.id}</Text>
          </Col>
        </Flex>
        <Flex justify="space-between">
          <Col span={10}>
            <Text data-testid={`${subscription.id}:subscription-price`} weight={500}>
              Base Price
            </Text>
          </Col>
          <Col span={14}>
            <Text>
              {Currency[subscription.currencyCode as keyof typeof Currency]}
              &nbsp;
              {subscription.monthlyPrice} / month
            </Text>
          </Col>
        </Flex>
        <Flex justify="space-between">
          <Col span={10}>
            <Text data-testid={`${subscription.id}:subscription-sessions`} weight={500}>
              {subscription.type === ISubscriptionType.web ? 'Sessions' : 'Daily Active Users (DAU)'}
            </Text>
          </Col>
          <Col span={14}>
            <Text>{numberWithCommas(subscription.maxValue || subscription.quantity)} / month</Text>
          </Col>
        </Flex>
        <Flex justify="space-between">
          <Col span={10}>
            <Text data-testid={`${subscription.id}:subscription-payment-method`} weight={500}>
              Payment Method
            </Text>
          </Col>
          <Col span={14}>
            <Text>{renderPaymentSource(primaryPaymentMethod)}</Text>
          </Col>
        </Flex>
        {!isReadOnly && showUpgradeButton && (
          <Button
            data-testid="button:upgrade-trial"
            onClick={onUpgrade}
            icon={<LightningIcon />}
            style={{ marginLeft: '12px', marginTop: '16px', width: '110px' }}
          >
            Upgrade
          </Button>
        )}
      </Flex>
    </Card>
  );
};

export default SubscriptionCard;
