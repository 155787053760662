import { Modal } from 'antd';
import styled from 'styled-components';

interface IProps {
  hideCloseIcon?: boolean;
}

export const UpgradeModal = styled(Modal)<IProps>`
  & .ant-modal-content {
    border-radius: 8px;
    padding: 0;

    & .ant-modal-close {
      top: 40px;
      margin-right: 20px;
    }

    & .ant-modal-close-x {
      color: ${({ theme }) => theme.colors.primaryText};
      display: ${(props) => (props.hideCloseIcon ? 'none' : 'block')};
    }
  }

  & .ant-modal-header {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    padding: 32px 32px 24px 32px;
    margin-bottom: 0;

    & .anticon {
      display: flex;
      padding: 8px;
      background-color: ${({ theme }) => theme.colors.blue[4]};
      justify-content: center;
      align-items: center;
      border: 1px solid ${({ theme }) => theme.colors.primary}1a;
      border-radius: 4px;
      width: 40px;
      height: 40px;

      svg {
        width: 21px;
        height: 21px;
      }
    }
  }

  & .ant-modal-body {
    padding: 0 32px;
  }

  & .ant-modal-footer {
    border: 0;
    display: flex;
    justify-content: space-between;

    &:not(:empty) {
      padding: 10px 32px 32px;

      & button:only-child {
        width: 100%;
        padding: 12px 0;
        margin: 0 auto;
      }

      & button:not(:only-child) {
        padding: 12px 20px;
      }
    }
  }

  & .ant-modal-title {
    display: flex;
    align-items: center;

    span {
      font-size: 20px;
    }

    & .anticon {
      margin-right: 12px;
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TitleIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.gray[4]}};
  margin-right: 12px;
`;
