import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = ({ color }: { color?: 'gradient-blue' | string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_3534_15212)">
      <path
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V12H20V17C20 17.55 19.55 18 19 18ZM20 8H4V6H20V8Z"
        fill={color === 'gradient-blue' ? 'url(#paint0_linear_670_3690)' : color || '#303030'}
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_670_3690"
        x1="11"
        y1="11"
        x2="30.0211"
        y2="11.0223"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0045A5" />
        <stop offset="1" stopColor="#0096FF" />
      </linearGradient>
      <clipPath id="clip0_3534_15212">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default (props: IconProps) => <Icon component={() => CustomIcon({ color: props.color })} {...props} />;
