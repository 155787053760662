import { SubscriptionConsumptionDto } from 'api/requests/generated/generated.schemas';
import { LS_KEYS } from 'interfaces/enums';

export const getAverageConsumption = (consumption: SubscriptionConsumptionDto) =>
  Math.round(
    consumption && consumption.overuse.details.metrics.currentSeries
      ? consumption.overuse.details.metrics.currentSeries?.reduce(
          (acc, period) => acc + consumption.breakdown[period].total,
          0,
        ) / consumption.overuse.details.metrics.currentSeries?.length
      : 0,
  );

export const dismissUpgrade = (subscriptionId: string) => {
  try {
    const current = JSON.parse(localStorage.getItem(LS_KEYS.UC_FORCE_SHOW_UPGRADE_SUBS) || '{}');
    const updated = { ...current, [subscriptionId]: new Date() };
    localStorage.setItem(LS_KEYS.UC_FORCE_SHOW_UPGRADE_SUBS, JSON.stringify(updated));
  } catch (e) {
    console.warn("Couldn't store information in the local storage", e);
  }
};

const isMoreThanAWeek = (date: Date): boolean => {
  const currentDate = new Date();
  const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const dateDifference = currentDate.getTime() - date.getTime();

  return dateDifference > oneWeekInMilliseconds;
};

export const shouldShowForceUpgrade = (subscriptionId: string) => {
  try {
    const data = JSON.parse(localStorage.getItem(LS_KEYS.UC_FORCE_SHOW_UPGRADE_SUBS) || '{}');
    const isMoreThanWeek = isMoreThanAWeek(new Date(data[subscriptionId]));
    return !data[subscriptionId] || isMoreThanWeek;
  } catch (e) {
    console.warn("Couldn't get information from the local storage", e);
    return false;
  }
};
