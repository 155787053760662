import { Row, Col, Radio, Button, Typography, Form } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { LegalFramework } from 'api/requests/generated/generated.schemas';
import { Input } from 'components/Form';
import { Expand } from 'components/Icons';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { frameworks } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { colors } from 'lib/theme/colors';
import { FrameworkItem } from 'pages/Companies/Configurations/Modals/CreateConfiguration/Steps/LegalFramework';

import { Wrapper } from '../../styled';

const { Title, Paragraph } = Typography;
const { Item } = Form;

const initialValues = {
  name: `${LegalFramework.GDPR} configuration #1`,
  framework: LegalFramework.GDPR,
};

interface IFrameworkSelectorProps {
  form: FormInstance;
  setDisabled: (value: boolean) => void;
}

const FrameworkSelector = ({ setDisabled, form }: IFrameworkSelectorProps) => {
  const [showMoreClicked, setShowMoreClicked] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    if (
      values &&
      values.framework &&
      (!values.name || frameworks.find(({ name }) => values.name === `${name} configuration #1`))
    ) {
      form.setFieldValue('name', `${values.framework} configuration #1`);
    }

    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabled(false);
        if (values) {
          TagManager.dataLayer({
            dataLayer: {
              event: TrackingEvents.ONBOARDING_CONFIGURATION_SELECTED,
              [TrackingVariables.CONFIGURATION_FRAMEWORK]: values.framework,
            },
          });
          trackEvent(TrackingEvents.ONBOARDING_CONFIGURATION_SELECTED, {
            [TrackingVariables.CONFIGURATION_FRAMEWORK]: values.framework,
          });
        }
      },
      () => {
        setDisabled(true);
      },
    );
  }, [values]);

  useEffect(() => {
    if (frameworks.slice(0, 3).some((framework) => framework.value === form.getFieldValue('framework'))) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }, []);

  return (
    <Form name="create-configuration" form={form} initialValues={initialValues} layout="vertical">
      <Row gutter={40}>
        <Col flex="220px">
          <Title level={3} data-testid="empty-placeholder:title">
            Configuration Details
          </Title>
          <Paragraph>Add your configuration details here.</Paragraph>
        </Col>
        <Col flex="auto">
          <Wrapper>
            <Item
              label="Configuration Name"
              name="name"
              colon={false}
              rules={[{ required: true, message: 'Please enter a name for your Configuration' }]}
              style={{ margin: 0 }}
            >
              <Input placeholder="My Configuration" />
            </Item>
          </Wrapper>
        </Col>
      </Row>
      <Row gutter={40} style={{ marginTop: 40 }}>
        <Col flex="220px">
          <Title level={3} data-testid="empty-placeholder:title">
            Legal Framework
          </Title>
          <Paragraph>Select your legal framework.</Paragraph>
        </Col>
        <Col flex="min-content">
          <Wrapper>
            <Item name="framework" required>
              <Radio.Group>
                <Row gutter={[0, 8]}>
                  {frameworks.map((framework, index) => (
                    // Need to show only 3 frameworks by default
                    <Col key={framework.value} span={showMoreClicked || index < 3 ? 24 : 0}>
                      <FrameworkItem focused={framework.value === values?.framework} framework={framework} />
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Item>
            <Row justify="center">
              {!showMoreClicked && (
                <Button
                  type="link"
                  style={{ alignItems: 'center', display: 'flex', fontWeight: 500 }}
                  onClick={() => setShowMoreClicked(true)}
                >
                  Show More <Expand style={{ margin: 0 }} color={colors.primary} />
                </Button>
              )}
            </Row>
          </Wrapper>
        </Col>
      </Row>
    </Form>
  );
};

export default FrameworkSelector;
