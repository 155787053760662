import { Col, Row, Space, Flex } from 'antd';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { getCompaniesWithSettings } from 'api/requests/companies';
import Button from 'components/Button';
import EmptyCompaniesPlaceholder from 'components/EmptyListPlaceholder/EmptyCompaniesList';
import { ArrowRight, Review as ReviewIcon } from 'components/Icons';
import NotFound from 'components/NotFound';
import PageInfo from 'components/PageInfo';
import Search from 'components/Search';
import { PermissionTag } from 'components/Tag';
import openSettingInAI from 'lib/helpers/openSettingInAI';
import { usePrivileges } from 'lib/hooks';
import { Counter } from 'shared-components';

import Skeleton from './Skeleton';
import { Company, CompanyName, CompanyInfo, Setting } from './styled';
import { Container } from '../../Configurations/styled';
import AddCompanyModal from '../Modals/AddCompany';

const Companies = () => {
  const { isCSA, hasAccounts } = usePrivileges();
  const navigate = useNavigate();
  const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { companies, isLoading, trigger, isMutating } = getCompaniesWithSettings(!isCSA && !searchTerm);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 970px)',
  });

  useEffect(() => {
    setSearchTerm('');
  }, [isCSA]);

  const sortedCompanies =
    !isCSA || searchTerm ? companies?.sort((a, b) => b?.createdAt.localeCompare(a?.createdAt)) : [];

  const canCreateCompany = isCSA || hasAccounts;

  const onSearch = async (value: string) => {
    setSearchTerm(value);

    if (isCSA && !value) {
      return;
    }

    await trigger(value);
  };

  return (
    <Container isBigScreen={isBigScreen}>
      <Flex align="start" justify="space-between">
        <PageInfo
          title="Companies"
          description={
            isCSA
              ? 'Below you can search for any company.'
              : 'Below you will find an overview of all companies to which you have access.'
          }
        />
        {canCreateCompany && (
          <Button
            onClick={() => setIsAddCompanyOpen(true)}
            type="primary"
            size="large"
            data-testid="button:add-company-open"
          >
            Add Company
          </Button>
        )}
      </Flex>

      {(searchTerm || isMutating || companies.length > 0 || isCSA) && (
        <Search
          data-testid="input:company-search"
          placeholder={
            isCSA ? 'Search for Company Name, Configuration ID or Email' : 'Search for Company Name or Configuration ID'
          }
          onSearch={onSearch}
          isLoading={isMutating}
        />
      )}
      {isLoading || isMutating ? (
        <Skeleton />
      ) : sortedCompanies?.length && !isMutating ? (
        sortedCompanies.map((company) => (
          <Company key={company.id} data-testid={`div:company-name[${company.name}]`}>
            <CompanyInfo onClick={() => navigate(`/company/${company.id}/configurations`)}>
              <Col>
                <CompanyName data-testid="span:company-info">{company.name}</CompanyName>
                <Counter count={company.settingsAmount} showZero data-testid="span:settings-counter" />
              </Col>
              <Col>
                <Flex align="center" gap={5}>
                  <Row>{company.reviewMode && <ReviewIcon data-testid="icon:review-mode" />}</Row>
                  <PermissionTag data-testid="label:company-permission">{company.customerPermission}</PermissionTag>
                  <ArrowRight />
                </Flex>
              </Col>
            </CompanyInfo>
            {company.settings?.map((setting) => (
              <Setting
                data-testid={`div:setting-id[${setting.settingId}]`}
                key={setting.settingId}
                onClick={() => openSettingInAI(setting.settingId, setting.selectedSettingsAppVersion)}
              >
                <Col>
                  {setting?.aliasName} [{setting.settingId}]
                </Col>
                <Space size="small" align="center">
                  <Row>{setting.reviewMode && <ReviewIcon />}</Row>
                  {setting?.settingPermission && (
                    <PermissionTag data-testid="label:company-permission">{setting.settingPermission}</PermissionTag>
                  )}
                  <ArrowRight />
                </Space>
              </Setting>
            ))}
          </Company>
        ))
      ) : searchTerm && !isMutating ? (
        <NotFound />
      ) : (
        !isCSA && !isMutating && !isLoading && <EmptyCompaniesPlaceholder />
      )}
      <AddCompanyModal
        open={isAddCompanyOpen}
        onClose={() => setIsAddCompanyOpen(false)}
        mutate={() => onSearch('')}
        isCSA={isCSA}
      />
    </Container>
  );
};

export default Companies;
