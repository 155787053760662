import styled from 'styled-components';

export const UnassignedLabel = styled.span`
  color: ${({ theme }) => theme.colors.gray[80]};
  white-space: nowrap;
`;

export const Container = styled.div<{ isBigScreen: boolean }>`
  max-width: 90%;
  min-width: ${(props) => (!props.isBigScreen ? '450px' : '700px')};
`;
