import { Checkbox as AntCheckbox, Alert } from 'antd';
import styled from 'styled-components';

export const Section = styled.div<{ withBackground?: boolean }>`
  width: 19.875rem;
  border-radius: 10px;
  padding: 1.5rem;
  ${(props) => {
    return props.withBackground
      ? `background-color: ${props.theme.colors.gray[8]}; border: 0;`
      : `background-color: transparent; border: 1px solid ${props.theme.colors.gray[24]};`;
  }}

  & .ant-space {
    width: 100%;
  }
`;

export const Content = styled.div<{ alignToCenter?: boolean }>`
  margin-top: 1.5rem;
  ${(props) => props?.alignToCenter && 'text-align: center'};
`;

export const ListContainer = styled.div`
  li {
    font-size: 14px;
    margin: 0 0 10px 20px;
    line-height: 18px;
  }
`;

export const ListItem = styled.div`
  margin-bottom: 16px;
  font-size: 15px;
  display: flex;
  align-items: start;

  & .anticon {
    margin-right: 12px;
  }
`;

export const Container = styled.div<{ isAvailable: boolean }>`
  display: ${(props) => (props.isAvailable ? 'block' : 'none')};
  margin-bottom: 32px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 8px;
`;

export const PayMethod = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 18px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.gray[8]}};
  border-radius: 4px;

  & svg {
    margin-right: 14px;
  }
`;

export const Checkbox = styled(AntCheckbox)`
  & .ant-checkbox {
    top: 0.35em;
  }
`;

export const Discount = styled(Alert)`
  background: ${({ theme }) => theme.colors.green[24]};
  border: none;
  color: ${({ theme }) => theme.colors.primaryText};
  font-weight: 600;
`;

export const ExceededMessage = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.yellow[8]};
  margin-bottom: 16px;
`;

export const Orange = styled.span`
  color: ${(props) => props.theme.colors.orange[100]};
  font-weight: bold;
`;

export const Tier = styled.div<{ isSelected: boolean }>`
  border: ${(props) => (props.isSelected ? 2 : 1)}px solid
    ${(props) => (props.isSelected ? props.theme.colors.primary : props.theme.colors.gray[24])};
  background: ${(props) => (props.isSelected ? props.theme.colors.blue[4] : 'white')};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => props.theme.colors.blue[50]};
    background: ${(props) => props.theme.colors.blue[4]};
  }
`;
