import { DropdownProps, Dropdown } from 'antd';
import { useState } from 'react';

interface IDropdownProps extends DropdownProps {
  items: IItem[];
}
interface IItem {
  label?: string | React.ReactNode;
  onClick?: (() => void | Promise<void>) | null;
  key?: string;
  hidden?: boolean;
}

export default ({ items, children, onOpenChange, ...props }: IDropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }

    onOpenChange?.(nextOpen, info);
  };

  const menuItems = items?.map(({ label = '', key = '', onClick = null, ...item }) => {
    if (item?.hidden) {
      return null;
    }

    return {
      label: (
        <div
          onClick={(e) => {
            setOpen(false);
            e.stopPropagation();

            if (onClick) {
              onClick();
            }
          }}
        >
          {label}
        </div>
      ),
      key: key,
      ...item,
    };
  });

  return (
    <Dropdown
      menu={{ items: menuItems }}
      onOpenChange={handleOpenChange}
      open={open}
      placement="bottomRight"
      trigger={['click']}
      {...props}
    >
      {children}
    </Dropdown>
  );
};
