import { useAuth0 } from '@auth0/auth0-react';
import { Col, Flex, Space, Switch } from 'antd';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import Dropdown from 'components/Dropdown';
import {
  User as UserIcon,
  Help as HelpIcon,
  KnowledgeBase as KnowledgeBaseIcon,
  Documentation as DocumentationIcon,
  Headphones as HeadphonesIcon,
  Feedback as FeedbackIcon,
  ArrowRight,
} from 'components/Icons';
import Logo from 'components/Logo';
import { PermissionTag } from 'components/Tag';
import { Text, Link } from 'components/Typography';
import { useLogout, usePrivileges, useSelectedCompany, useSelectedAccount } from 'lib/hooks';
import useAmplitude from 'lib/hooks/useAmplitude';

import {
  Header,
  SupportAccessLabel,
  SupportAccess,
  UserWrapper,
  DateLabel,
  UserInfoWrapper,
  UserName,
  HeaderItem,
  SelectedCompany,
  Delimiter,
  DropdownItem,
} from './styled';

interface IHeaderComponent {
  hideInfo?: boolean;
  isBillingHeader?: boolean;
}

const getLinkByLanguage = () => {
  const { language } = navigator;

  switch (language) {
    case 'pt-PT':
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/1831469272';
    case 'de-DE':
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/141557846';
    default:
      return 'https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/176979988';
  }
};

const HeaderComponent = ({ hideInfo, isBillingHeader }: IHeaderComponent) => {
  const { user } = useAuth0();
  const { deviceId } = useAmplitude();
  const navigate = useNavigate();
  const logOut = useLogout(true);
  const { isSupport, isCSA, hasAccounts, hasSettings, toggleCSA, isLoading } = usePrivileges();
  const selectedCompany = useSelectedCompany();
  const selectedAccount = useSelectedAccount();
  const displaySupportAccess = !hideInfo && isSupport && !isBillingHeader;

  const getUserGroup = () => {
    if (isCSA) {
      return 'supportUser';
    }

    const settingsAmount = hasSettings?.modern?.assigned + hasSettings?.modern?.unassigned + hasSettings?.legacy;

    return settingsAmount > 0 ? 'oldUser' : 'newUser';
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'local') {
      const { Userlane } = window;

      if (Userlane && !isLoading) {
        Userlane('identify', user?.email || '', {
          application: 'Account Management',
          accountmanagementusergroup: getUserGroup(),
          environment: process.env.REACT_APP_ENV || 'sandbox',
        });
        // agreed with Felix until this functionality will be clarified with Userlane
        /*if (process.env.REACT_APP_E2E_TESTS !== '1') {
            Userlane('startOnce', '81259');
          }*/

        if (user) {
          window.beamer_config = {
            ...(window.beamer_config || {}),
            user_firstname: user.given_name || '',
            user_lastname: user.family_name || '',
            user_email: user.email || '',
          };
        }
      }
    }
  }, [user, isLoading]);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1000px)',
  });

  const userMenuItems = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserIcon />
          <Col>
            <UserName>{user?.name}</UserName>
            <p>{user?.email}</p>
            <Space direction="vertical">
              {user?.updated_at && (
                <DateLabel>Last login: {new Date(user?.updated_at).toLocaleString('en-GB')}</DateLabel>
              )}
              {isSupport && <PermissionTag>SUPPORT</PermissionTag>}
            </Space>
          </Col>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: <DropdownItem>Account & Billing</DropdownItem>,
      'data-testid': 'header:account-billing',
      onClick: () => navigate('/billing/account'),
      hidden: !hasAccounts,
    },
    {
      key: '4',
      label: <DropdownItem>Logout</DropdownItem>,
      onClick: logOut,
    },
  ];

  const knowledgeBaseItems = [
    {
      key: '1',
      icon: <KnowledgeBaseIcon />,
      label: (
        <Link color="text" href={getLinkByLanguage()} target="_blank">
          Knowledge Base
        </Link>
      ),
    },
    {
      key: '2',
      icon: <DocumentationIcon />,
      label: (
        <Link color="text" href={`https://docs.usercentrics.com/?deviceId=${deviceId}`} target="_blank">
          Documentation
        </Link>
      ),
    },
    {
      key: '3',
      icon: <HeadphonesIcon />,
      label: (
        <Link color="text" href="https://usercentricssupport.zendesk.com/hc/en-us/requests/new" target="_blank">
          Support
        </Link>
      ),
    },
    {
      key: '4',
      icon: <FeedbackIcon />,
      label: <Text>Send Feedback</Text>,
      onClick: () => window.Beamer.forceShowNPS(),
    },
  ];

  return (
    <Header $hideInfo={hideInfo}>
      <Logo width={151} />

      {!hideInfo && (
        <>
          <HeaderItem data-testid="link:overview" to="/companies">
            {isBillingHeader ? (
              <>
                <ArrowRight />
                <span>BACK TO OVERVIEW</span>
              </>
            ) : isCSA ? (
              isBigScreen ? (
                'CUSTOMER SUPPORT ACCESS'
              ) : (
                'CSA'
              )
            ) : (
              'OVERVIEW'
            )}
          </HeaderItem>
          {selectedCompany.id && (
            <Flex align="center" gap={10}>
              <SelectedCompany>
                <Delimiter>&#8963;</Delimiter>
                <span data-testid="header:company-name">{selectedCompany.name.toUpperCase()}</span>
              </SelectedCompany>
              {!isCSA && <PermissionTag>{selectedCompany.permission}</PermissionTag>}
            </Flex>
          )}
          {selectedAccount.id && (
            <Space>
              <SelectedCompany>
                <Delimiter>&#8963;</Delimiter>
                <span data-testid="header:company-name">{selectedAccount.name.toUpperCase()}</span>
              </SelectedCompany>
            </Space>
          )}
        </>
      )}

      {displaySupportAccess && (
        <SupportAccess>
          <SupportAccessLabel>{isBigScreen ? 'Customer Support Access' : 'CSA'}</SupportAccessLabel>
          <Switch
            onChange={() => toggleCSA()}
            defaultChecked={isCSA}
            style={{ marginRight: '24px' }}
            data-testid="toggle:csa"
          />
        </SupportAccess>
      )}

      {!hideInfo && (
        <UserWrapper align="center" style={!displaySupportAccess ? { marginLeft: 'auto' } : {}}>
          <Dropdown items={knowledgeBaseItems} align={{ offset: [-10, 0] }}>
            <span className="ant-dropdown-link">
              <HelpIcon />
            </span>
          </Dropdown>
          <Dropdown items={userMenuItems} align={{ offset: [-10, 0] }}>
            <span className="ant-dropdown-link" data-testid="header:user">
              <UserIcon />
            </span>
          </Dropdown>
        </UserWrapper>
      )}
    </Header>
  );
};

export default HeaderComponent;
