const KEY = 'uc_aci_feature_flags';

export const UC_UPSELL = 'MON-43';
export const SHOW_UPGRADE_SCREEN = 'MON-135';
export const ACCOUNT_BILLING_ACCESS = 'MON-112';

export const getFeatureFlags = () => {
  try {
    return localStorage.getItem(KEY)?.split(',') || [];
  } catch (e) {
    return [];
  }
};
