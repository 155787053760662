import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Layout } from 'antd';

import Dropdown from 'components/Dropdown';
import { UserInfoWrapper, UserName, UserWrapper } from 'components/Header/styled';
import { User as UserPlaceholder } from 'components/Icons';
import { useLogout } from 'lib/hooks';

const { Paragraph } = Typography;
const { Header } = Layout;

const HeaderContent = () => {
  const logout = useLogout(true);
  const { user } = useAuth0();

  const userMenuItems = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserPlaceholder />
          <div>
            <UserName>{user?.name}</UserName>
            <Paragraph>{user?.email}</Paragraph>
          </div>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: 'Logout',
      onClick: logout,
    },
  ];

  return (
    <Header>
      <UserWrapper justify="end">
        <Dropdown items={userMenuItems} align={{ offset: [-10, 0] }}>
          <UserPlaceholder />
        </Dropdown>
      </UserWrapper>
    </Header>
  );
};

export default HeaderContent;
