import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { SearchSettingOutDto } from 'api/requests/generated/generated.schemas';
import ConfigurationTable from 'components/ConfigurationTable';
import NotFound from 'components/NotFound';
import PageInfo from 'components/PageInfo';
import Search from 'components/Search';
import { searchFilters } from 'lib/consts';
import { usePrivileges, useNewConfigurations } from 'lib/hooks';
import { useSettingsSearch } from 'lib/hooks/useSettingsSearch';

import { generateConfigurationColumns } from './columns';
import { Container } from './styled';

// This page is for CSA mode only
// ./Unassigned.tsx is for normal users
const Configurations = () => {
  const { isCSA } = usePrivileges();
  const navigate = useNavigate();

  const { newConfigurations } = useNewConfigurations();
  const { settings, isLoading, onSearch, onFiltersChange, mutate, currentSearchFilter } = useSettingsSearch();

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1250px)',
  });

  useEffect(() => {
    if (!isCSA) {
      navigate('/unassigned');
    }
  }, [isCSA]);

  return (
    <Container isBigScreen={isBigScreen}>
      <PageInfo title="Configurations" description="Below you can search for any configuration." />
      <Search
        filterItems={searchFilters}
        defaultFilterOption={searchFilters[1]}
        onFilterChange={onFiltersChange}
        onSearch={onSearch}
        isLoading={isLoading}
        placeholder={`Search for configuration by ${currentSearchFilter?.label} parameter`}
      />
      <ConfigurationTable<SearchSettingOutDto>
        columns={generateConfigurationColumns(newConfigurations)}
        isLoading={isLoading}
        settings={settings}
        notFound={<NotFound />}
        withoutPermissionsStep
        mutate={mutate}
        disableDuplicate
      />
    </Container>
  );
};

export default Configurations;
