import { Form, Typography } from 'antd';
import { FormInstance } from 'antd/lib';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import { Item, Input } from 'components/Form';
const { Title, Paragraph } = Typography;

interface IDetails {
  type: SubscriptionType;
  form: FormInstance;
}
const initialValues = { dataController: '', name: '' };

const Details = ({ type, form }: IDetails) => {
  return (
    <>
      <Title level={3}>Configuration Details</Title>
      <Paragraph>
        {type === SubscriptionType.web
          ? 'Let us know how your configuration should be named, on which domain it will be installed as well as the responsible Data Controller for the respective domain.'
          : 'Let us know how your configuration should be named and the ID of the app the CMP will be integrated on, as well as the responsible Data Controller.'}
      </Paragraph>
      <Form name="details" form={form} initialValues={initialValues}>
        <Item
          colon={false}
          required
          label="Configuration Name"
          name="name"
          rules={[{ required: true, message: 'Input required' }]}
        >
          <Input data-testid="input:configuration-name" placeholder="Configuration Name" />
        </Item>
        {type === SubscriptionType.web && (
          <Item
            colon={false}
            required
            label="Domain"
            name="domain"
            tooltip="Enter the (sub-)domain(s) on which the CMP will be integrated."
            validateTrigger="onBlur"
            rules={[{ required: true, message: 'Input required' }]}
          >
            <Input data-testid="input:domain" addonBefore="https://" placeholder="example.com" />
          </Item>
        )}
        <Item
          colon={false}
          label="Data Controller"
          name="dataController"
          tooltip="The data controller is the person or legal entity that is responsible for the used platform of this CMP. This role can be shared by more than one person, company, or other organization."
          data-testid="input:data-controller"
          validateTrigger="onBlur"
        >
          <Input placeholder="Data Controller" />
        </Item>
      </Form>
    </>
  );
};

export default Details;
