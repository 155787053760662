import Icon from '@ant-design/icons';

import { IconProps } from 'types';

const CustomIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <g stroke="none" fill="none">
      <g transform="translate(-1272.000000, -282.000000)">
        <g transform="translate(272.000000, 216.000000)">
          <g transform="translate(0.000000, 51.000000)">
            <g transform="translate(1000.000000, 15.000000)">
              <g transform="translate(10.000000, 10.000000) scale(-1, 1) rotate(-180.000000) translate(-10.000000, -10.000000) ">
                <polygon points="0 0 20 0 20 20 0 20" />
                <path
                  d="M4.16666667,10.8333333 L13.475,10.8333333 L9.40833333,14.9 C9.08333333,15.225 9.08333333,15.7583333 9.40833333,16.0833333 C9.73333333,16.4083333 10.2583333,16.4083333 10.5833333,16.0833333 L16.075,10.5916667 C16.4,10.2666667 16.4,9.74166667 16.075,9.41666667 L10.5916667,3.91666667 C10.2666667,3.59166667 9.74166667,3.59166667 9.41666667,3.91666667 C9.09166667,4.24166667 9.09166667,4.76666667 9.41666667,5.09166667 L13.475,9.16666667 L4.16666667,9.16666667 C3.70833333,9.16666667 3.33333333,9.54166667 3.33333333,10 C3.33333333,10.4583333 3.70833333,10.8333333 4.16666667,10.8333333 Z"
                  id="Path"
                  fill="#303030"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default (props: IconProps) => <Icon component={CustomIcon} {...props} />;
