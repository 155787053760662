import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 36.563rem;
  max-width: 62.5rem;
  width: 100%;
`;

export const AccountBlock = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.gray[24]};
  border-radius: 4px;
  cursor: pointer;
  padding: 20px;
`;
