import { useAuth0 } from '@auth0/auth0-react';
import { Form, Typography } from 'antd';
import { useEffect, useState } from 'react';

import {
  createCustomer,
  createCustomerByBillingUser,
  createOrUpdateCompanyPermissions,
  useGetMyAccounts,
} from 'api/requests';
import {
  AccountOutDto,
  Permission,
  SetPermissionInDto,
  SubscriptionStatus,
} from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import Permissions from 'components/Permissions';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';
import { useMessage } from 'lib/hooks';

import CompanyDetails, { ICompanyDetailsFields } from '../Details/Modals/CompanyDetails';

const { Title, Paragraph } = Typography;
const { useForm, useWatch } = Form;

interface IAddCompanyProps {
  open: boolean;
  hasAccounts?: boolean;
  isCSA?: boolean;
  onClose: () => void;
  mutate: () => void;
}

const checkDisabledAndGetTooltip = (account: AccountOutDto) => {
  if (account.permission === Permission.READ) return 'You have read permission for this billing account';
  if (account.subscriptions.every((subscription) => subscription.status === SubscriptionStatus.cancelled)) {
    return "The account doesn't have an active subscription";
  }
  return null;
};

const AddCompany = ({ open, onClose, mutate, isCSA }: IAddCompanyProps) => {
  const { user: currentUser } = useAuth0();
  const [companyDetailsForm] = useForm<ICompanyDetailsFields>();
  const companyValues = useWatch([], { form: companyDetailsForm });
  const [isDisabled, setIsDisabled] = useState(true);
  const message = useMessage();

  const { data: accounts } = useGetMyAccounts();

  const initialPermission = {
    login: currentUser?.email || '',
    permission: Permission.ADMIN,
    inherited: false,
    id: '',
    parentId: '',
    notify: false,
  };

  const [permissions, setPermissions] = useState<SetPermissionInDto[]>(() => [initialPermission]);

  const filteredAccounts =
    !isCSA && accounts?.body
      ? accounts.body.map((account) => ({
          ...account,
          disabled: checkDisabledAndGetTooltip(account),
        }))
      : [];

  useEffect(() => {
    companyDetailsForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsDisabled(false);
      },
      () => {
        setIsDisabled(true);
      },
    );
  }, [companyValues]);

  if (!accounts?.body) {
    return null;
  }

  const onRemovePermission = (login: string) => {
    setPermissions(permissions.filter((p) => p.login !== login));
  };

  const onUpdatePermission = (login: string, newPermission: Permission) => {
    setPermissions(permissions.map((p) => (p.login === login ? { ...p, permission: newPermission } : p)));
  };

  const onAddPermission = (data: SetPermissionInDto) => {
    setPermissions([...permissions.filter((p) => p.login !== data.login), data]);
  };

  const addCompany = async () => {
    try {
      const company = companyDetailsForm.getFieldsValue(true);

      if (company) {
        const { account, ...details } = company;
        const {
          body: { id },
        } = company?.account
          ? await createCustomerByBillingUser(account, { ...details })
          : await createCustomer({ customer: company });
        await Promise.all(
          permissions.map((p) =>
            createOrUpdateCompanyPermissions(id, {
              permission: p,
            }),
          ),
        );

        trackEvent(TrackingEvents.COMPANY_CREATED, {
          [TrackingVariables.COMPANY_NAME]: company.name,
          [TrackingVariables.COMPANY_BILLING_ACCOUNT]: company.account,
          [TrackingVariables.COMPANY_COUNTRY]: company.country,
          [TrackingVariables.COMPANY_CITY]: company.city,
        });

        message.success('Company was created successfully');
        mutate();
      }
    } catch (error) {
      if (error?.message) {
        message.error(error.response?.data.error?.msg);
      }
    }
  };

  const onModalClose = () => {
    companyDetailsForm.resetFields();
    onClose();
  };

  return (
    <StepModal
      open={open}
      title="Create Company"
      okText="Create Company"
      destroyOnClose
      width={740}
      bodyHeight={100}
      maskClosable={false}
      onClose={onModalClose}
      steps={[
        {
          content: (
            <>
              <Title level={3}>Company Details</Title>
              <Paragraph>Which company do you want to add?</Paragraph>
              <CompanyDetails form={companyDetailsForm} accounts={filteredAccounts} />
            </>
          ),
          primaryButton: {
            disabled: isDisabled,
          },
        },
        {
          content: (
            <Permissions
              permissions={permissions}
              onAdd={onAddPermission}
              onRemove={onRemovePermission}
              onUpdate={onUpdatePermission}
            />
          ),
          handleNext: addCompany,
        },
      ]}
    />
  );
};

export default AddCompany;
