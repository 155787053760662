import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet, useNavigate } from 'react-router-dom';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import Footer from 'components/Footer';
import Header from 'components/Header';
import UpgradePlan from 'components/Modal/UpgradePlan';
import SideBar from 'components/SideBar';
import { TrackingEvents, TrackingVariables, LS_KEYS } from 'interfaces/enums';
import { getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { dismissUpgrade } from 'lib/helpers/overusage';
import { useTrial, useUpgradePlan, usePrivileges, useUsageStatus, useSelectedCompany } from 'lib/hooks';

import { Layout, ContentContainer, Content, Container, StatusBarsContainer } from './styled';
import NewFeatureBar from '../NewFeatureBar';
import TrialStatusBar from '../TrialStatusBar';
import UsageStatusBar from '../UsageStatusBar';

interface IMainLayoutProps {
  hideInfo?: boolean;
}

const ucUpsellNewFeatureDissmissed = localStorage.getItem(LS_KEYS.UC_UPSELL_NEW_FEATURE_DISSMISS);

const MainLayout = ({ hideInfo }: IMainLayoutProps) => {
  const { subscription, setPlanToUpgrade, exceeded } = useUpgradePlan();
  const { readonly, shouldShowLockoutScreen, shouldShowTrialBar, subscriptionsInTrial = [], expiredTrial } = useTrial();
  const { shouldShowUsageBar } = useUsageStatus();
  const { isCSA, hasAccounts } = usePrivileges();
  const [isLockoutOpen, setIsLockoutOpen] = useState(true);
  const { billingCustomerId } = useSelectedCompany();
  const navigate = useNavigate();
  const [showNewFeature, setShowNewFeature] = useState(false);

  const barHeight = 56;
  const headerHeight = 64;
  const contentMarginTop =
    headerHeight +
    (shouldShowTrialBar ? barHeight * subscriptionsInTrial.length : 0) +
    (showNewFeature && !shouldShowUsageBar ? barHeight : 0) +
    (shouldShowUsageBar ? barHeight : 0);

  useEffect(() => {
    setShowNewFeature(
      () =>
        new Date().getTime() <= new Date('08-07-2024').getTime() &&
        !ucUpsellNewFeatureDissmissed &&
        !isCSA &&
        hasAccounts,
    );
  }, [isCSA, hasAccounts]);

  useEffect(() => {
    setIsLockoutOpen(Boolean(shouldShowLockoutScreen));
  }, [shouldShowLockoutScreen]);

  const onUpgradeClose = () => {
    if (exceeded?.forcedUpgrade && subscription) {
      trackEvent(TrackingEvents.UPGRADE_SUBSCRIPTION_DISMISSED, {
        [TrackingVariables.SUBSCRIPTION_ID]: subscription.id,
      });
      dismissUpgrade(subscription.id);
    }
    setPlanToUpgrade(null);
  };

  if (isLockoutOpen && expiredTrial) {
    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.TRIAL_EXPIRED,
        [TrackingVariables.PLAN_NAME]: getPlanName(expiredTrial),
        [TrackingVariables.TRIAL_EXPIRED_DATE]: expiredTrial.trialFinishedAt || '',
        [TrackingVariables.PLATFORM]: expiredTrial.type || SubscriptionType.web,
      },
    });

    trackEvent(TrackingEvents.TRIAL_EXPIRED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(expiredTrial),
      [TrackingVariables.TRIAL_EXPIRED_DATE]: expiredTrial.trialFinishedAt || '',
      [TrackingVariables.PLATFORM]: expiredTrial.type || SubscriptionType.web,
    });
    return <UpgradePlan open trial={expiredTrial} readonly={readonly} onClose={() => setIsLockoutOpen(false)} />;
  }

  const onDissmissNewFeature = (shouldNavigate?: boolean) => {
    localStorage.setItem(LS_KEYS.UC_UPSELL_NEW_FEATURE_DISSMISS, '1');
    setShowNewFeature(false);
    if (shouldNavigate) {
      navigate('/billing/account#subscription-row', { state: { billingCustomerId } });
      trackEvent(TrackingEvents.UC_UPSELL_FEATURE_EXPLORED);
    } else {
      trackEvent(TrackingEvents.UC_UPSELL_FEATURE_DISMISSED);
    }
  };

  return (
    <Layout>
      {subscription && <UpgradePlan open onClose={onUpgradeClose} />}
      <Header hideInfo={hideInfo} />
      <StatusBarsContainer>
        {shouldShowTrialBar && <TrialStatusBar readonly={readonly} trials={subscriptionsInTrial} />}
        {shouldShowUsageBar && <UsageStatusBar />}
        {showNewFeature && !shouldShowUsageBar && (
          <NewFeatureBar
            feature="Keep track of your monthly subscription usage on the Account & Billing page."
            onClose={() => onDissmissNewFeature()}
            onExplore={() => {
              onDissmissNewFeature(true);
            }}
          />
        )}
      </StatusBarsContainer>
      <Container style={{ top: contentMarginTop + 'px' }}>
        <SideBar />
        <ContentContainer style={{ minHeight: `calc(100vh - ${contentMarginTop}px)` }}>
          <Content>
            <Outlet />
          </Content>
          <Footer />
        </ContentContainer>
      </Container>
    </Layout>
  );
};

export default MainLayout;
