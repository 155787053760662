import { Col, Flex, Radio, Row, Typography } from 'antd';

import { LegalFramework as ILegalFramework } from 'api/requests/generated/generated.schemas';
import Card from 'components/Card';
import Tag from 'components/Tag';
import { Text, Title } from 'components/Typography';
import { IFramework } from 'interfaces/requests/createSetting';
import { frameworks } from 'lib/consts';
import { colors } from 'lib/theme/colors';

import { StyledRadio } from './styled';

const { Paragraph } = Typography;

interface ILegalFrameworkProps {
  setFramework: (value: ILegalFramework) => void;
  framework: ILegalFramework;
}

interface ILegalFrameworkItemProps {
  framework: IFramework;
  focused: boolean;
}

export const FrameworkItem = ({ framework, focused }: ILegalFrameworkItemProps) => {
  const { value, name, expansion, icon, region } = framework;

  return (
    <Card focused={focused} className="framework">
      <StyledRadio value={value}>
        <img src={require(`assets/${icon}.svg`)} alt={icon} width={32} height={24} />
        <Flex style={{ flex: 1 }} vertical>
          <Text weight={500}>{name}</Text>
          <Text size="sm" style={{ color: colors.secondary }} ellipsis>
            {expansion}
          </Text>
        </Flex>
        <Tag color="blue-inverse" $bolder size="medium">
          {region}
        </Tag>
      </StyledRadio>
    </Card>
  );
};

const LegalFramework = ({ setFramework, framework }: ILegalFrameworkProps) => {
  return (
    <>
      <Title level={3}>Framework Type</Title>
      <Paragraph>
        Select the legal framework your configuration should support. Depending on your choice, there will be a default
        setup prepared including all required features to be compliant with the selected framework.{' '}
      </Paragraph>
      <br />
      <Radio.Group value={framework}>
        <Row gutter={[0, 6]}>
          {frameworks.map((currFramework) => (
            <Col span={24} onClick={() => setFramework(currFramework.value)} key={currFramework.name}>
              <FrameworkItem focused={currFramework.value === framework} framework={currFramework} />
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </>
  );
};

export default LegalFramework;
