import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import ChargebeeComponents from '@chargebee/chargebee-js-react-wrapper/dist/components/ComponentGroup';
import { PaymentIntent } from '@chargebee/chargebee-js-types';
import { FormInstance, Typography, Form, Row, Flex, Col } from 'antd';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { createPaymentIntent, createPaymentSource, createSubscriptionInChargebee, getEstimation } from 'api/requests';
import { EstimationOutDto } from 'api/requests/generated/generated.schemas';
import { Input, Item } from 'components/Form';
import { Card } from 'components/Icons';
import { Text, Title } from 'components/Typography';
import VatInput from 'components/VatInput';
import { Currency } from 'interfaces/enums';
import { COUNTRIES_WITH_MANDATORY_VAT_ID } from 'lib/consts';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { getPlanNameFromPackage } from 'lib/helpers';
import useMessage from 'lib/hooks/useMessage';

import { Container, PayMethod } from './styled';

import './styles.css';
const { Paragraph } = Typography;

interface IPaymentDetails {
  subscription: IUpgradeSubscription;
  initialEstimation?: EstimationOutDto;
  scriptStatus: { isInited: boolean } | null;
  nameForm: FormInstance;
  expiredTrial: boolean;
  isAvailable: boolean;
  setIsPaymentDisabled: (value: boolean) => void;
}

export interface RefType {
  onConfirmPayment: () => Promise<unknown | null | undefined>;
}

const styles = {
  base: {
    '::placeholder': {
      color: '#cecece',
    },
  },
  empty: {},
  invalid: {},
};

const initialErrorsFields = {
  name: '',
  number: '',
  expiry: '',
  cvv: '',
};

const PaymentDetailsForm = (
  {
    subscription,
    initialEstimation,
    setIsPaymentDisabled,
    nameForm,
    expiredTrial,
    isAvailable,
    scriptStatus,
  }: IPaymentDetails,
  ref: Ref<RefType>,
) => {
  const message = useMessage();
  const cardRef = useRef<ChargebeeComponents>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>(initialErrorsFields);
  const [estimation, setEstimation] = useState<EstimationOutDto>();
  const currencyCode = (estimation?.currencyCode || 'EUR') as keyof typeof Currency;
  const [isVatExcluded, setVatExcluded] = useState(true);

  const total = estimation?.perBillingCycle?.total || 0;

  const isVatNumberMandatory = COUNTRIES_WITH_MANDATORY_VAT_ID.includes(subscription.country);

  useImperativeHandle(ref, () => ({
    async onConfirmPayment() {
      try {
        if (cardRef.current && estimation) {
          const { body: paymentIntent } = await createPaymentIntent(subscription.billingCustomerId, {
            amount: total,
            currencyCode,
          });

          const { active_payment_attempt: activePaymentAttempt, id: paymentIntentId } =
            await cardRef.current.authorizeWith3ds(
              paymentIntent as unknown as PaymentIntent,
              { cardBillingAddress: { firstName: nameForm.getFieldValue('name') } },
              { error: () => message.error('Unfortunately your request could not be processed. Please try again.') },
            );

          if (activePaymentAttempt?.id_at_gateway) {
            if (expiredTrial) {
              return createSubscriptionInChargebee(subscription.billingCustomerId, subscription.id, {
                gatewayToken: activePaymentAttempt.id_at_gateway,
              });
            }

            return createPaymentSource(subscription.billingCustomerId, {
              paymentIntentId,
            });
          }
        }
        return null;
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    },
  }));

  useEffect(() => {
    if (isVatNumberMandatory) {
      setVatExcluded(true);
    } else {
      setVatExcluded(estimation?.perMonth?.total === estimation?.perMonth?.taxableAmount);
    }
  }, [estimation]);

  useEffect(() => {
    (async () => {
      if (isVatNumberMandatory && !subscription.vatNumber) {
        setErrors((prevErrors) => ({ ...prevErrors, vatId: 'VAT ID required' }));
      }

      if (!initialEstimation && isAvailable) {
        await reestimate();
      } else {
        setEstimation(initialEstimation);
      }
    })();
  }, [isAvailable]);

  useEffect(() => {
    setIsPaymentDisabled(Boolean(Object.keys(errors).length));
  }, [errors]);

  const reestimate = async () => {
    const updatedEstimation = (await getEstimation(subscription.id)).body;

    setEstimation(updatedEstimation);
  };

  if (!scriptStatus?.isInited) {
    return null;
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Input Required' }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setErrors(({ name: fieldToExclude, ...fields }) => fields);
    }
  };

  const onChargebeeInputChange = (status: React.ChangeEvent & { field: string; error: Error }) => {
    const { field, error } = status;

    if (error?.message) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setErrors(({ [field]: fieldToExclude, ...fields }) => fields);
    }
  };

  const onVatNumberInputChange = () => {
    setErrors((prevErrors) => ({ ...prevErrors, vatId: 'VAT ID requires validation' }));
  };

  const onVatNumberValidationChanged = async (isValid: boolean) => {
    if (isValid) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setErrors(({ vatId, ...fields }) => fields);
      await reestimate();
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, vatId: 'VAT ID is not valid' }));
    }
  };

  const getTotalPostfix = () => {
    return isVatExcluded ? '' : ' (incl. VAT)';
  };

  return (
    <Container isAvailable={isAvailable}>
      <Flex justify="space-between">
        <Text size="sm">Payment Overview</Text>
        <Text size="sm">Total{getTotalPostfix()}</Text>
      </Flex>
      <Flex justify="space-between" align="center">
        <Title level={3}>{getPlanNameFromPackage(estimation?.package as string)}</Title>
        <Title level={3} align="right" data-testid="div:total-amount" style={{ marginTop: 0 }}>
          {Currency[currencyCode]}
          {total}
        </Title>
      </Flex>
      <PayMethod>
        <Card /> Pay by Credit Card
      </PayMethod>
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form form={nameForm}>
            <Item
              label="Name"
              name="name"
              colon={false}
              requiredMark={false}
              rules={[{ required: true, message: 'Input required' }]}
            >
              <Input placeholder="Name on card" onChange={onNameChange} />
            </Item>
          </Form>
        </Col>

        <CardComponent
          ref={cardRef}
          styles={styles}
          placeholder={{ number: '1234 1234 1234 1234', expiry: 'MM/YY', cvv: 'CVC' }}
          onChange={onChargebeeInputChange}
        >
          <Row data-testid="input:card-number" gutter={[10, 16]}>
            <Col span={24}>
              <Text strong>Card number</Text>
              <CardNumber className="chargebee-input" />
              <Text type="danger" size="sm">
                {errors?.['number']}
              </Text>
            </Col>
            <Col span={12}>
              <Text strong>Expiration</Text>
              <CardExpiry className="chargebee-input" />
              <Text type="danger" size="sm">
                {errors?.['expiry']}
              </Text>
            </Col>
            <Col span={11}>
              <Text strong>Security Code</Text>
              <CardCVV className="chargebee-input" />
              <Text type="danger" size="sm">
                {errors?.['cvv']}
              </Text>
            </Col>
          </Row>
        </CardComponent>
        <Col>
          <VatInput
            country={subscription.country}
            accountId={subscription.billingCustomerId}
            initialVatNumber={subscription.vatNumber}
            onChange={onVatNumberInputChange}
            onIsValidChanged={onVatNumberValidationChanged}
          />
        </Col>
      </Row>

      <br />
      <Paragraph>
        By confirming your payment, you agree that Usercentrics GmbH may charge your card for this and future payments
        in accordance with its terms.
      </Paragraph>
    </Container>
  );
};

export default forwardRef(PaymentDetailsForm);
