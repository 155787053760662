/* eslint-disable prefer-promise-reject-errors */

import { useAuth0 } from '@auth0/auth0-react';
import { Row, Col, Typography, Form, Checkbox, Divider } from 'antd';
import { FormInstance } from 'antd/lib';
import Cookies from 'js-cookie';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { SubscriptionType } from 'api/requests/generated/generated.schemas';
import AddressItem from 'components/AddressItem';
import { Input } from 'components/Form';
import Item from 'components/Form/Item';
import { StyledSelect } from 'components/Select/Select';
import { Link } from 'components/Typography';
import { Currency, Links, TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { USER_AUTH0_PARAMETERS, ONBOARDING_TYPE } from 'lib/consts';
import { trackEvent } from 'lib/helpers/amplitude';
import { domainRegexp } from 'lib/helpers/validation';
import useAmplitude from 'lib/hooks/useAmplitude';

import { CheckboxItem, Wrapper } from '../../styled';

const { Title, Paragraph } = Typography;

interface IDetailsProps {
  form: FormInstance;
  setDisabled: (value: boolean) => void;
  subscriptionType: SubscriptionType;
}

const Details = ({ form, setDisabled, subscriptionType }: IDetailsProps) => {
  const currencyList = Object.entries(Currency).map(([code, symbol]) => ({ code, symbol }));
  const values = Form.useWatch([], form);
  const { deviceId } = useAmplitude();
  const { user } = useAuth0();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabled(false);
        TagManager.dataLayer({
          dataLayer: {
            event: TrackingEvents.ONBOARDING_DETAILS_FILLED,
            [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
            [TrackingVariables.PLATFORM]: subscriptionType,
            [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
              Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
            ),
          },
        });
        trackEvent(TrackingEvents.ONBOARDING_DETAILS_FILLED, {
          [TrackingVariables.TRIAL_TYPE]: user?.[USER_AUTH0_PARAMETERS.uc_onboarding] || ONBOARDING_TYPE.STANDARD,
          [TrackingVariables.PLATFORM]: subscriptionType,
          [TrackingVariables.LEAD_SOURCE]: decompressFromEncodedURIComponent(
            Cookies.get(USER_AUTH0_PARAMETERS.uc_lead_source) || '',
          ),
        });
      },
      () => {
        setDisabled(true);
      },
    );
  }, [values]);

  return (
    <Row gutter={40}>
      <Col flex="220px">
        <Title level={3} data-testid="empty-placeholder:title">
          Account Information
        </Title>
        <Paragraph>Add your company details here.</Paragraph>
      </Col>
      <Col flex="min-content">
        <Form name="company-details" form={form} initialValues={{ currency: 'EUR' }} layout="vertical">
          <Wrapper>
            <Item
              label="Company Name"
              name="companyName"
              colon={false}
              rules={[{ required: true, message: 'Required field' }]}
              weight="medium"
              requiredMark={false}
            >
              <Input placeholder="Company Name" autoFocus />
            </Item>
            <Item
              label="Company Website"
              name="domain"
              colon={false}
              weight="medium"
              rules={[
                { required: true, message: 'Required field' },
                { pattern: domainRegexp, message: 'Please enter a valid domain string' },
              ]}
              requiredMark={false}
            >
              <Input placeholder="example.com" />
            </Item>
            <AddressItem form={form} />
            <Row>
              <Col span={8}>
                <Item
                  label="Currency"
                  name="currency"
                  colon={false}
                  weight="medium"
                  rules={[{ required: true, message: 'Required field' }]}
                  requiredMark={false}
                >
                  <StyledSelect
                    data-testid="select:currency"
                    size="large"
                    $itemSize="sm"
                    options={currencyList.map((currency) => ({
                      value: currency.code,
                      label: currency.code + ' - ' + currency.symbol,
                    }))}
                  />
                </Item>
              </Col>
            </Row>
            <Divider />
            <CheckboxItem
              name="confirmation"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Required field')),
                },
              ]}
            >
              <Checkbox>
                I confirm that I have read and accept the{' '}
                <Link size="sm" strong href={`${Links.TERMS_AND_CONDITIONS}?deviceId=${deviceId}`} target="_blank">
                  Terms and Conditions
                </Link>
                {' / '}
                <Link size="sm" strong href={`${Links.TERMS_AND_CONDITIONS_USA}?deviceId=${deviceId}`} target="_blank">
                  Terms and Conditions (USA)
                </Link>{' '}
                and the{' '}
                <Link size="sm" strong href={`${Links.DPA_US}?deviceId=${deviceId}`} target="_blank">
                  Data Processing Agreement
                </Link>{' '}
                of the Usercentrics Consent Management Platform. My email address may be used for direct advertising and
                marketing about the products and services offered by Usercentrics GmbH. I can revoke my consent for
                advertising purposes at any time via{' '}
                <Link size="sm" strong href={'mailto:' + Links.USERCENTRICS_EMAIL_UNSUBSCRIBE}>
                  {Links.USERCENTRICS_EMAIL_UNSUBSCRIBE}
                </Link>
                .
              </Checkbox>
            </CheckboxItem>
          </Wrapper>
        </Form>
      </Col>
    </Row>
  );
};

export default Details;
