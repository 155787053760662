import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { createSetting } from 'api/requests';
import {
  GetAvailableTypesOutDto,
  LegalFramework as ILegalFramework,
  SubscriptionType,
} from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { ICreateSettingForm } from 'interfaces/requests/createSetting';
import { trackEvent } from 'lib/helpers/amplitude';
import { useMessage, useNewConfigurations } from 'lib/hooks';

import { Details, LegalFramework, Type } from './Steps';

const { useForm, useWatch } = Form;

interface ICreateConfigurationModal {
  open: boolean;
  companyId: string;
  canSelectLegalFramework: boolean;
  availableConfigurationsTypes: GetAvailableTypesOutDto;
  onClose: () => Promise<void>;
}

const CreateConfigurationModal = ({
  open,
  onClose,
  companyId,
  canSelectLegalFramework,
  availableConfigurationsTypes,
}: ICreateConfigurationModal) => {
  const [framework, setFramework] = useState<ILegalFramework>(ILegalFramework.GDPR);
  const [isDisabled, setIsDisabled] = useState(false);
  const [type, setType] = useState<SubscriptionType>(SubscriptionType.web);
  const [detailsForm] = useForm<ICreateSettingForm>();
  const detailsFormValues = useWatch([], detailsForm);
  const { markConfigurationsAsNew } = useNewConfigurations();
  const message = useMessage();

  const { isAppAvailable, isWebAvailable } = availableConfigurationsTypes;

  useEffect(() => {
    detailsForm.validateFields({ validateOnly: true }).then(
      () => {
        setIsDisabled(false);
      },
      () => {
        setIsDisabled(true);
      },
    );
  }, [detailsFormValues]);

  useEffect(() => {
    if (isAppAvailable && !isWebAvailable) {
      setType(SubscriptionType.app);
    }

    if (!isAppAvailable && isWebAvailable) {
      setType(SubscriptionType.web);
    }
  }, []);

  const createConfiguration = async () => {
    const details = detailsForm.getFieldsValue();

    if (details) {
      try {
        const {
          body: { settingId },
        } = await createSetting({ companyId, ...details, type, legalFramework: framework });
        markConfigurationsAsNew([settingId]);
        /**
         * Tracking events
         */
        trackEvent(TrackingEvents.CONFIGURATION_CREATED, {
          [TrackingVariables.CONFIGURATION_FRAMEWORK]: framework,
          [TrackingVariables.PLATFORM]: type,
        });
        /**
         * ======
         */
        message.success('Configuration created successfully');
      } catch (error) {
        message.error(error.response?.data.error?.msg);
      }
    }
  };

  return (
    <StepModal
      open={open}
      title="Create Configuration"
      okText="Create Configuration"
      isConfirmation={false}
      width={760}
      onClose={onClose}
      steps={[
        canSelectLegalFramework
          ? {
              content: <LegalFramework framework={framework} setFramework={setFramework} />,
            }
          : undefined,
        isAppAvailable && isWebAvailable
          ? {
              content: <Type setType={setType} type={type} />,
            }
          : undefined,
        {
          content: <Details type={type} form={detailsForm} />,
          handleNext: createConfiguration,
          primaryButton: {
            disabled: isDisabled,
          },
        },
      ]}
    />
  );
};

export default CreateConfigurationModal;
