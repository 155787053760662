import { Layout as AntLayout, Flex } from 'antd';
import styled from 'styled-components';

import { pr } from 'shared-components/styled';

export const Layout = styled(AntLayout)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primaryText};

  .ant-layout-content.scroll {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 16px;

    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.gray[50]};
      border-radius: 10px;
      width: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.gray[24]};
    }
  }
`;

export const Container = styled(Flex)`
  ${pr}
  top: 64px;
`;

export const StatusBarsContainer = styled.div`
  position: fixed;
  top: 64px;
  width: 100vw;
  z-index: 100;
`;

export const ContentContainer = styled.div`
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NotAuthorizedContent = styled(AntLayout.Content)`
  background: ${({ theme }) => theme.colors.blue[4]};
  height: calc(100vh - 4rem - 3.938rem);
`;

export const Content = styled.div`
  padding: 40px;
  margin-left: 15.6875rem;
  margin-bottom: auto;
`;
