import { Flex } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { useSearchUnassignedSettings } from 'api/requests';
import { getCompanies } from 'api/requests/companies';
import { Permission, UnassignedSettingsOutDto } from 'api/requests/generated/generated.schemas';
import unassignedPlaceholder from 'assets/placeholder_unassigned.svg';
import Button from 'components/Button';
import ConfigurationTable from 'components/ConfigurationTable';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import ManageConfigurations from 'components/Modal/ManageConfigurations';
import NotFound from 'components/NotFound';
import PageInfo from 'components/PageInfo';
import Search from 'components/Search';
import { useNewConfigurations, usePrivileges } from 'lib/hooks';

import { generateConfigurationColumns } from './columns';
import { Container, Counter } from './styled';
import { commonSwrConfig } from '../../api/config/swr';

// This page is for non CSA mode only
// ./Configurations.tsx is for CSA mode
const Unassigned = () => {
  const navigate = useNavigate();
  const { isCSA } = usePrivileges();

  const [queryString, setQueryString] = useState('');
  const [isManageConfigurationsOpen, setIsManageConfigurationsOpen] = useState(false);

  const { isLoading, data, mutate } = useSearchUnassignedSettings({ q: queryString }, { swr: commonSwrConfig });
  const { newConfigurations } = useNewConfigurations();
  const { companies } = getCompanies(!isCSA);

  const isBigScreen = useMediaQuery({
    query: '(min-width: 1250px)',
  });

  const configurations = data?.body || [];

  const possibleConfigurationsToManage = configurations?.filter(
    (configuration) =>
      configuration.permission === Permission.ADMIN || configuration.permission === Permission.LEGACY_ADMIN,
  );

  const isAllowedToManageConfigurations = possibleConfigurationsToManage.length > 0;

  const userHasMoreCompanies = useMemo(() => Boolean(companies?.length), [companies]);
  const isSearchModeDisabled = useMemo(
    () => !Boolean(queryString) && !Boolean(configurations?.length) && !isLoading,
    [configurations, isLoading, queryString],
  );

  useEffect(() => {
    if (isCSA) {
      navigate('/configurations');
    }
  }, [isCSA]);

  return (
    <Container isBigScreen={isBigScreen}>
      <Flex align="start" justify="space-between">
        <PageInfo
          title="Unassigned Configurations"
          description="The following configurations have not been assigned to a company yet."
        />
        {isAllowedToManageConfigurations && (
          <Button onClick={() => setIsManageConfigurationsOpen(true)} data-testid="button:manage-configurations">
            Manage configurations
          </Button>
        )}
      </Flex>

      {!isSearchModeDisabled && (
        <Search
          placeholder="Search for Configuration Name, ID, Domain or Data Controller"
          onSearch={(value) => setQueryString(value)}
          data-testid="input:unassigned-configurations-search"
          isLoading={isLoading}
        />
      )}
      <ConfigurationTable<UnassignedSettingsOutDto>
        columns={generateConfigurationColumns(newConfigurations)}
        style={{ maxWidth: '1300px' }}
        isLoading={isLoading}
        pagination
        settings={configurations}
        notFound={!isSearchModeDisabled ? <NotFound /> : null}
        mutate={mutate}
        withoutPermissionsStep
        canMove={userHasMoreCompanies}
      />
      {Boolean(configurations.length) && <Counter>{configurations.length} Unassigned Configurations found</Counter>}

      {isSearchModeDisabled && (
        <EmptyListPlaceholder
          title="No configurations found"
          description="Currently there are no unassigned configurations."
          image={unassignedPlaceholder}
        />
      )}

      {isManageConfigurationsOpen && (
        <ManageConfigurations
          open
          configurations={possibleConfigurationsToManage}
          withoutPermissionsStep
          onSaveCb={() => mutate()}
          onClose={() => {
            setIsManageConfigurationsOpen(false);
          }}
        />
      )}
    </Container>
  );
};

export default Unassigned;
