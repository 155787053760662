import { Row, Skeleton, Space, Typography, Flex } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useEffect } from 'react';

import { TrialSubscriptionOutDto, EstimationOutDto, SubscriptionType } from 'api/requests/generated/generated.schemas';
import Button from 'components/Button';
import { Success as SuccessIcon, Verified } from 'components/Icons';
import { Title, Text, Link } from 'components/Typography';
import { Currency, TrackingEvents, TrackingVariables, Links } from 'interfaces/enums';
import { getPlanName, isDmaCoupon, numberWithCommas } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import useAmplitude from 'lib/hooks/useAmplitude';

import { Checkbox, Content, Discount, ListContainer, ListItem, Section } from './styled';

const { Paragraph } = Typography;

interface IUpgradePlan {
  subscription: TrialSubscriptionOutDto;
  areTermsAgreed: boolean;
  isLockout: boolean;
  readonly?: boolean;
  isAllFeaturesVisible?: boolean;
  setAreTermsAgreed: (value: boolean) => void;
  estimation?: EstimationOutDto;
  handleNext: () => void;
}

const getAmount = (estimation: EstimationOutDto | undefined) => numberWithCommas(estimation?.tier.maxValue || 50000);
const getUnits = (type: SubscriptionType) => (type === SubscriptionType.app ? 'DAU (Daily Active Users)' : 'sessions');

const getPlanInfo = (estimation: EstimationOutDto | undefined, sessions: string) => {
  if (!estimation) {
    return { price: '', description: '', priceBeforeDiscount: '' };
  }

  const currencySign = Currency[estimation.currencyCode as keyof typeof Currency];
  const taxableAmountPerMonth = estimation.perMonth.totalWithoutDiscount;
  const price = `${currencySign}${estimation.perMonth.taxableAmount}`;

  const inclVatSuffix = estimation.perMonth.total === estimation.perMonth.taxableAmount ? '' : ' (incl. VAT)';
  const fixedTotal = estimation.perYear.total.toFixed(2);

  const description = `The contract period is 12 months, billed at ${currencySign}${fixedTotal} per year${inclVatSuffix}, up to ${sessions} per month. This is equivalent to ${currencySign}${taxableAmountPerMonth} per month, the contract period starts on the first day after the end of the free-trial-phase. It is automatically extended by another year respectively. Period of notice: 4 weeks to the end of each contract period.`;

  return { price, description };
};

const featuresList = [
  'Keep full access to all configurations',
  'Remain legally compliant',
  'Optimize your CMP performance',
  'Get access to upcoming features',
];

const Upgrade = ({
  readonly,
  subscription,
  isLockout,
  handleNext,
  areTermsAgreed,
  setAreTermsAgreed,
  isAllFeaturesVisible = true,
  estimation,
}: IUpgradePlan) => {
  const amountOfSessions = `${getAmount(estimation)} ${getUnits(subscription.type)}`;
  const { price, description } = getPlanInfo(estimation, amountOfSessions);
  const { deviceId } = useAmplitude();
  const discount = estimation?.discount?.discountPercentage;

  const onCheckboxChange = (event: CheckboxChangeEvent) => {
    setAreTermsAgreed(event.target.checked);
  };

  if (!subscription) return null;

  /**
   * Tracking events
   */
  useEffect(() => {
    trackEvent(TrackingEvents.PAYMENT_WALL_VIEWED, {
      [TrackingVariables.PLAN_NAME]: getPlanName(subscription),
    });
  }, []);
  /**
   * ======
   */
  return (
    <>
      <Flex justify="space-between">
        <Section withBackground>
          <Title level={3}>
            {isLockout
              ? 'Upgrade your plan to regain full access to your account'
              : 'Retain the full potential of collecting consents'}
          </Title>
          {isLockout && (
            <Paragraph>Otherwise your configurations and companies will be removed after 30 days</Paragraph>
          )}
          <Content>
            {featuresList.map((feature) => (
              <ListItem key={feature}>
                <SuccessIcon /> <span>{feature}</span>
              </ListItem>
            ))}
            {isAllFeaturesVisible && (
              <Link strong href={`https://usercentrics.com/pricing?deviceId=${deviceId}`} target="_blank">
                See all features included
              </Link>
            )}
          </Content>
        </Section>
        <Section>
          <Skeleton loading={!estimation}>
            <Space direction="vertical" size={18}>
              {discount && (
                <Discount
                  message={isDmaCoupon(subscription?.couponId) ? '1 month for free' : `${discount}% discount applied`}
                  icon={<Verified />}
                  showIcon
                />
              )}
              <Row justify="space-between">
                <div>
                  <Text size="sm">Your Plan</Text>
                  <Title level={3} $noMargin>
                    {getPlanName(subscription)}
                  </Title>
                </div>
                <div>
                  <Title level={4}>{price}</Title>
                  <Row>
                    <Text size="sm">per month</Text>
                  </Row>
                </div>
              </Row>
              <ListContainer>
                <ul>
                  <li>Unlimited configurations</li>
                  <li>Unlimited domains</li>
                  <li>Up to {amountOfSessions}</li>
                </ul>
              </ListContainer>
            </Space>
            <Text size="sm">{description}</Text>
          </Skeleton>
        </Section>
      </Flex>
      {isLockout && readonly && (
        <Content alignToCenter data-testid="div:upgrade-email-info">
          Please sign in with your Billing Account <br /> {estimation?.billingEmail} to proceed with the upgrade
        </Content>
      )}
      {!readonly && (
        <Content data-testid="div:terms-and-conditions">
          <Checkbox checked={areTermsAgreed} onChange={onCheckboxChange}>
            I hereby confirm that I have read and accept the{' '}
            <Link strong href={`${Links.TERMS_AND_CONDITIONS}?deviceId=${deviceId}`} target="_blank">
              Terms and Conditions
            </Link>
            {' / '}
            <Link strong href={`${Links.TERMS_AND_CONDITIONS_USA}?deviceId=${deviceId}`} target="_blank">
              Terms and Conditions (USA)
            </Link>{' '}
            of the Usercentrics Consent Management Platform. The previously accepted Data Processing Agreement is still
            valid. My email address may be used for direct advertising and marketing covering the products and services
            offered by Usercentrics GmbH. I can revoke this usage of my email address for advertising purposes at any
            time via{' '}
            <Link strong href={'mailto:' + Links.USERCENTRICS_EMAIL_UNSUBSCRIBE}>
              {Links.USERCENTRICS_EMAIL_UNSUBSCRIBE}
            </Link>
            .
          </Checkbox>
        </Content>
      )}

      <Button
        type="primary"
        disabled={!areTermsAgreed}
        data-testid="button:buy-now"
        block
        style={{ padding: 12, margin: '20px 0' }}
        onClick={handleNext}
      >
        Buy Now
      </Button>
    </>
  );
};

export default Upgrade;
