import { Flex } from 'antd';
import { useMemo } from 'react';

import Button from 'components/Button';
import { Clock, Lightning } from 'components/Icons';
import { TrackingEvents, TrackingVariables } from 'interfaces/enums';
import { IUpgradeSubscription } from 'lib/contexts/UpgradePlan';
import { isDmaCoupon, getPlanName } from 'lib/helpers';
import { trackEvent } from 'lib/helpers/amplitude';
import { useUpgradePlan } from 'lib/hooks';

import { Trial } from './styled';

const getDaysLeft = (expirationDate: string | null) => {
  if (!expirationDate) {
    return 0;
  }

  const date1 = new Date().getTime();
  const date2 = new Date(expirationDate).getTime();
  const diffTime = date2 - date1;
  if (diffTime <= 0) {
    return 0;
  }
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const getAccountName = (accountName: string) => (accountName ? `${accountName} - ` : '');

interface Props {
  readonly?: boolean;
  trials: IUpgradeSubscription[];
}

const TrialStatusBar = ({ readonly, trials }: Props) => {
  const { setPlanToUpgrade } = useUpgradePlan();

  const mapped = useMemo(
    () =>
      trials.map((trial) => ({
        ...trial,
        daysLeft: getDaysLeft(trial.trialFinishedAt),
        name: `${trial.pricePlan} ${trial.type.toUpperCaseFirst()} Trial`,
        discount: trial?.coupon?.discountPercentage || '',
        isDma: isDmaCoupon(trial?.couponId),
      })),
    [trials],
  );

  const onUpgradeClick = (trial: IUpgradeSubscription) => {
    /**
     * Tracking events
     */
    trackEvent(TrackingEvents.CTA_TRIAL_BAR_UPGRADE, {
      [TrackingVariables.SOURCE_PAGE]: 'Company',
    });
    /**
     * ====
     */

    setPlanToUpgrade(trial);
  };

  return (
    <>
      {mapped.map((trial) => (
        <Trial key={trial.id} data-testid="div:trial-status-bar" type="trial">
          <Flex align="center" data-testid="trial-status-bar_details">
            <Clock style={{ marginRight: '12px' }} />
            {getAccountName(trial.accountName)}
            {getPlanName(trial)} Trial: {trial.daysLeft} day{trial.daysLeft > 1 ? 's' : ''} left
          </Flex>
          {!readonly && (
            <Button ghost onClick={() => onUpgradeClick(trial)} icon={<Lightning />} data-testid="button:upgrade-trial">
              {trial.isDma
                ? 'Upgrade and get 1 month for free'
                : trial.discount
                ? `Upgrade with ${trial.discount}% discount`
                : 'Upgrade'}
            </Button>
          )}
        </Trial>
      ))}
    </>
  );
};

export default TrialStatusBar;
