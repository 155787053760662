import { Flex } from 'antd';
import { FlexProps } from 'antd/lib';
import styled from 'styled-components';

type Type = 'warning' | 'info' | 'trial';

const Container = styled(Flex)<{ $type: Type }>`
  width: 100vw;
  height: 56px;
  z-index: 100;
  padding: 12px 24px;
  background: ${(props) => {
    switch (props.$type) {
      case 'warning':
        return props.theme.colors.orange[100];
      case 'info':
        return props.theme.colors.green[100];
      case 'trial':
        return 'linear-gradient(90deg, #0045a5 0%, #0096ff 100%)';
      default:
        return props.theme.colors.green[100];
    }
  }};
  color: white;

  & svg path {
    fill: white;
  }
`;

interface IProps {
  type: Type;
  onDissmiss?: () => void;
  children: React.ReactNode;
}

const StatusBar = ({ type, ...props }: IProps & FlexProps) => (
  <Container justify="space-between" align="center" {...props} $type={type}>
    {props.children}
  </Container>
);

export default StatusBar;
