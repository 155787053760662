import styled from 'styled-components';

import { resetButton, pr, pa, flexBetween } from 'shared-components/styled';

export const Container = styled.div`
  ${flexBetween}
  width: calc(100vw - 15.6875rem);
  margin-left: 15.6875rem;
  padding: 16px 40px;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;

  & p {
    font-weight: 400;
  }

  & button {
    cursor: pointer;
    display: inline;
    ${resetButton}
  }

  & a {
    color: ${({ theme }) => theme.colors.secondary};
    margin-left: 28px;
    ${pr}

    &:before {
      content: '';
      ${pa}
      width: 4px;
      height: 4px;
      border-radius: 50%;
      right: calc(100% + 12px);
      top: calc(50% - 2px);
      background: ${({ theme }) => theme.colors.gray[24]};
    }
  }

  & a,
  & button {
    font-weight: 500;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;
