import { Row, Typography } from 'antd';
import styled from 'styled-components';

export const CodeWrapper = styled(Row)<{ $isActive: boolean; background?: 'white' | 'gray' }>`
  padding: 20px 10px 20px 20px;
  min-height: 76px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  background-color: ${(props) =>
    props.background === 'white' ? props.theme.colors.white : props.theme.colors.gray[8]};
  ${({ theme, $isActive }) => $isActive && `background-color: ${theme.colors.green[8]}`};

  &:hover {
    ${({ theme, $isActive }) => !$isActive && `background-color: ${theme.colors.blue[8]}`};
  }
`;

export const Code = styled.code`
  color: ${({ theme }) => theme.colors.primaryText};
  font-family: 'Courier New';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: inline-block;
  max-width: 100%;
  word-break: break-all;
  word-wrap: break-word;
`;

export const CopyText = styled(Typography.Text)`
  width: 65px;
  display: block;
  opacity: 1;

  &[hidden] {
    display: inherit !important;
    opacity: 0;
  }
`;
