import { Flex, Typography } from 'antd';

import { Permission } from 'api/requests/generated/generated.schemas';
import { Business, Configurations, Geolocation, UserManagement, Account } from 'components/Icons';
import { usePrivileges, useSelectedCompany } from 'lib/hooks';

import { Container, Item } from './styled';
const { Text } = Typography;

const SideBar = () => {
  const { isCSA } = usePrivileges();
  const { permission, id } = useSelectedCompany();

  const isHasAccess = isCSA || (permission !== Permission.READ && permission !== Permission.READWRITE);

  const nonePermissions = !isCSA && permission === Permission.NONE;

  return (
    <Container>
      {id ? (
        <>
          <Item to={`/company/${id}/configurations`}>
            <Flex align="center" gap={14}>
              <Configurations />
              <Text data-testid="sidebar-item:configurations">Configurations</Text>
            </Flex>
          </Item>
          {isHasAccess && !nonePermissions && (
            <>
              <Item to={`/company/${id}/geo-rulesets`}>
                <Flex align="center" gap={14}>
                  <Geolocation />
                  <Text data-testid="sidebar-item:rulesets">Geolocation Rulesets</Text>
                </Flex>
              </Item>
              <Item to={`/company/${id}/user-management`}>
                <Flex align="center" gap={14}>
                  <UserManagement />
                  <Text data-testid="sidebar-item:user-management">User Management</Text>
                </Flex>
              </Item>
            </>
          )}
          {!nonePermissions && (
            <Item to={`/company/${id}/details`}>
              <Flex align="center" gap={14}>
                <Business />
                <Text data-testid="sidebar-item:company-details">Company Details</Text>
              </Flex>
            </Item>
          )}
        </>
      ) : (
        <>
          {isCSA && (
            <Item to="/accounts">
              <Flex align="center" gap={14}>
                <Account />
                <Text data-testid="sidebar-item:accounts">Accounts</Text>
              </Flex>
            </Item>
          )}
          <Item to="/companies">
            <Flex align="center" gap={14}>
              <Business />
              <Text data-testid="sidebar-item:companies">Companies</Text>
            </Flex>
          </Item>
          <Item data-testid="sidebar-item:configurations" to={isCSA ? '/configurations' : '/unassigned'}>
            <Flex align="center" gap={14}>
              <Configurations />
              <Text>{isCSA ? 'Configurations' : 'Unassigned'}</Text>
            </Flex>
          </Item>
        </>
      )}
    </Container>
  );
};

export default SideBar;
