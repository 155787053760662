import React, { createContext } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import { useGetCustomerBaseInfo } from 'api/requests';
import { CustomerBaseInfoOutDto, OkResponseDto, Permission } from 'api/requests/generated/generated.schemas';

const EMPTY_COMPANY = {
  name: '',
  permission: 'NONE' as Permission,
  id: '',
  reviewMode: false,
  billingCustomerId: '',
  canSelectLegalFramework: false,
};

export const SelectedCompanyContext = createContext<{
  id: string;
  permission: Permission;
  name: string;
  billingCustomerId: string;
  reviewMode: boolean;
  isLoading: boolean;
  canSelectLegalFramework: boolean;
  mutate: KeyedMutator<OkResponseDto & { body: CustomerBaseInfoOutDto }>;
}>({
  ...EMPTY_COMPANY,
  isLoading: false,
  mutate: async () => undefined,
});

const getCompanyId = (pathname: string) => {
  const match = matchPath<'id', string>({ path: '/company/:id/:subpage' }, pathname);
  return match?.params?.id || null;
};

const SelectedCompanyProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const location = useLocation();
  const companyId = getCompanyId(location.pathname);

  const { data, mutate, isLoading } = useGetCustomerBaseInfo(companyId || '');

  const company = data?.body;

  const selectedCompany =
    !isLoading && companyId && company
      ? {
          billingCustomerId: company.billingCustomerId,
          name: company.name,
          permission: company.permission,
          reviewMode: company.reviewMode,
          isLoading: false,
          canSelectLegalFramework: company.canSelectLegalFramework,
          id: companyId,
        }
      : { ...EMPTY_COMPANY, isLoading };

  return (
    <SelectedCompanyContext.Provider value={{ ...selectedCompany, mutate }}>{children}</SelectedCompanyContext.Provider>
  );
};

export default SelectedCompanyProvider;
