import { useAuth0 } from '@auth0/auth0-react';

import Dropdown from 'components/Dropdown';
import { User as UserPlaceholder } from 'components/Icons';
import Logo from 'components/Logo';
import { useLogout } from 'lib/hooks';

import { Header, UserWrapper, DateLabel, UserInfoWrapper, UserName } from './styled';

const LightHeaderComponent = () => {
  const { user } = useAuth0();
  const logOut = useLogout(true);

  const userMenuItems = [
    {
      key: '1',
      label: (
        <UserInfoWrapper>
          <UserPlaceholder />
          <div>
            <UserName>{user?.name}</UserName>
            <p>{user?.email}</p>
            {user?.updated_at && (
              <DateLabel>Last login: {new Date(user?.updated_at).toLocaleString('en-GB')}</DateLabel>
            )}
          </div>
        </UserInfoWrapper>
      ),
    },
    {
      key: '2',
      type: 'divider',
    },
    {
      key: '3',
      label: 'Logout',
      onClick: logOut,
    },
  ];

  return (
    <Header>
      <Logo width={151} />
      <UserWrapper align="center">
        <Dropdown items={userMenuItems} align={{ offset: [-10, 0] }}>
          <span className="ant-dropdown-link">
            <UserPlaceholder />
          </span>
        </Dropdown>
      </UserWrapper>
    </Header>
  );
};

export default LightHeaderComponent;
