/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * Permission management API
 * Permission management API
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr';
import type { Arguments, Key, SWRConfiguration } from 'swr';
import useSWRMutation from 'swr/mutation';
import type { SWRMutationConfiguration } from 'swr/mutation';
import type {
  AttachSettingsToSubscriptionInDto,
  CreateSubscriptionBasedOnPreviousInDto,
  EstimationControllerEstimateSubscription200,
  EstimationControllerEstimateSubscriptionParams,
  FailResponseDto,
  SubscriptionControllerAttachSettingsToSubscription200,
  SubscriptionControllerAttachSettingsToSubscriptionParams,
  SubscriptionControllerCreateSubscriptionBasedOnPrevious200,
  SubscriptionControllerListSubscriptions200,
  SubscriptionControllerListSubscriptionsParams,
  SubscriptionTierControllerGetSubscriptionTiers200,
  SubscriptionTierControllerGetSubscriptionTiersParams,
} from '../generated.schemas';
import { customInstance } from '../../../config/baseApi';

/**
 * @summary Returns list of subscriptions of billing account of company
 */
export const subscriptionControllerListSubscriptions = (params: SubscriptionControllerListSubscriptionsParams) => {
  return customInstance<SubscriptionControllerListSubscriptions200>({ url: `/subscriptions`, method: 'GET', params });
};

export const getSubscriptionControllerListSubscriptionsKey = (params: SubscriptionControllerListSubscriptionsParams) =>
  [`/subscriptions`, ...(params ? [params] : [])] as const;

export type SubscriptionControllerListSubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerListSubscriptions>>
>;
export type SubscriptionControllerListSubscriptionsQueryError = FailResponseDto;

/**
 * @summary Returns list of subscriptions of billing account of company
 */
export const useSubscriptionControllerListSubscriptions = <TError = FailResponseDto>(
  params: SubscriptionControllerListSubscriptionsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof subscriptionControllerListSubscriptions>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getSubscriptionControllerListSubscriptionsKey(params) : null));
  const swrFn = () => subscriptionControllerListSubscriptions(params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Attaches settings to subscription
 */
export const subscriptionControllerAttachSettingsToSubscription = (
  id: string,
  attachSettingsToSubscriptionInDto: AttachSettingsToSubscriptionInDto,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
) => {
  return customInstance<SubscriptionControllerAttachSettingsToSubscription200>({
    url: `/subscriptions/${id}/settings`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: attachSettingsToSubscriptionInDto,
    params,
  });
};

export const getSubscriptionControllerAttachSettingsToSubscriptionMutationFetcher = (
  id: string,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
) => {
  return (_: string, { arg }: { arg: Arguments }): Promise<SubscriptionControllerAttachSettingsToSubscription200> => {
    return subscriptionControllerAttachSettingsToSubscription(id, arg as AttachSettingsToSubscriptionInDto, params);
  };
};
export const getSubscriptionControllerAttachSettingsToSubscriptionMutationKey = (id: string) =>
  `/subscriptions/${id}/settings` as const;

export type SubscriptionControllerAttachSettingsToSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>
>;
export type SubscriptionControllerAttachSettingsToSubscriptionMutationError = FailResponseDto;

/**
 * @summary Attaches settings to subscription
 */
export const useSubscriptionControllerAttachSettingsToSubscription = <TError = FailResponseDto>(
  id: string,
  params?: SubscriptionControllerAttachSettingsToSubscriptionParams,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof subscriptionControllerAttachSettingsToSubscription>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSubscriptionControllerAttachSettingsToSubscriptionMutationKey(id);
  const swrFn = getSubscriptionControllerAttachSettingsToSubscriptionMutationFetcher(id, params);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Creates subscription based on previous. Used during upgrade in grace period based on trial subscription
 */
export const subscriptionControllerCreateSubscriptionBasedOnPrevious = (
  billingAccountId: string,
  subscriptionId: string,
  createSubscriptionBasedOnPreviousInDto: CreateSubscriptionBasedOnPreviousInDto,
) => {
  return customInstance<SubscriptionControllerCreateSubscriptionBasedOnPrevious200>({
    url: `/accounts/${billingAccountId}/subscriptions/${subscriptionId}/new`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSubscriptionBasedOnPreviousInDto,
  });
};

export const getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationFetcher = (
  billingAccountId: string,
  subscriptionId: string,
) => {
  return (
    _: string,
    { arg }: { arg: Arguments },
  ): Promise<SubscriptionControllerCreateSubscriptionBasedOnPrevious200> => {
    return subscriptionControllerCreateSubscriptionBasedOnPrevious(
      billingAccountId,
      subscriptionId,
      arg as CreateSubscriptionBasedOnPreviousInDto,
    );
  };
};
export const getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationKey = (
  billingAccountId: string,
  subscriptionId: string,
) => `/accounts/${billingAccountId}/subscriptions/${subscriptionId}/new` as const;

export type SubscriptionControllerCreateSubscriptionBasedOnPreviousMutationResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>
>;
export type SubscriptionControllerCreateSubscriptionBasedOnPreviousMutationError = FailResponseDto;

/**
 * @summary Creates subscription based on previous. Used during upgrade in grace period based on trial subscription
 */
export const useSubscriptionControllerCreateSubscriptionBasedOnPrevious = <TError = FailResponseDto>(
  billingAccountId: string,
  subscriptionId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof subscriptionControllerCreateSubscriptionBasedOnPrevious>>
    > & { swrKey?: string };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ??
    getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationKey(billingAccountId, subscriptionId);
  const swrFn = getSubscriptionControllerCreateSubscriptionBasedOnPreviousMutationFetcher(
    billingAccountId,
    subscriptionId,
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns estimate for particular subscription (upgrade from trial/grace period)
 */
export const estimationControllerEstimateSubscription = (
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
) => {
  return customInstance<EstimationControllerEstimateSubscription200>({
    url: `/subscriptions/${subscriptionId}/estimate`,
    method: 'GET',
    params,
  });
};

export const getEstimationControllerEstimateSubscriptionKey = (
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
) => [`/subscriptions/${subscriptionId}/estimate`, ...(params ? [params] : [])] as const;

export type EstimationControllerEstimateSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof estimationControllerEstimateSubscription>>
>;
export type EstimationControllerEstimateSubscriptionQueryError = FailResponseDto;

/**
 * @summary Returns estimate for particular subscription (upgrade from trial/grace period)
 */
export const useEstimationControllerEstimateSubscription = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: EstimationControllerEstimateSubscriptionParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof estimationControllerEstimateSubscription>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getEstimationControllerEstimateSubscriptionKey(subscriptionId, params) : null));
  const swrFn = () => estimationControllerEstimateSubscription(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary Returns list of tiers for particular subscription
 */
export const subscriptionTierControllerGetSubscriptionTiers = (
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
) => {
  return customInstance<SubscriptionTierControllerGetSubscriptionTiers200>({
    url: `/subscriptions/${subscriptionId}/tiers`,
    method: 'GET',
    params,
  });
};

export const getSubscriptionTierControllerGetSubscriptionTiersKey = (
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
) => [`/subscriptions/${subscriptionId}/tiers`, ...(params ? [params] : [])] as const;

export type SubscriptionTierControllerGetSubscriptionTiersQueryResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionTierControllerGetSubscriptionTiers>>
>;
export type SubscriptionTierControllerGetSubscriptionTiersQueryError = FailResponseDto;

/**
 * @summary Returns list of tiers for particular subscription
 */
export const useSubscriptionTierControllerGetSubscriptionTiers = <TError = FailResponseDto>(
  subscriptionId: string,
  params?: SubscriptionTierControllerGetSubscriptionTiersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof subscriptionTierControllerGetSubscriptionTiers>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
  },
) => {
  const { swr: swrOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!subscriptionId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getSubscriptionTierControllerGetSubscriptionTiersKey(subscriptionId, params) : null));
  const swrFn = () => subscriptionTierControllerGetSubscriptionTiers(subscriptionId, params);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  });

  return {
    swrKey,
    ...query,
  };
};
