import { Space, Flex } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { deleteRuleset, updateRuleset, useGetCustomerRuleSets } from 'api/requests';
import { RuleSetInDto, RuleSetOutDto } from 'api/requests/generated/generated.schemas';
import { getCompanySettings } from 'api/requests/settings';
import emptyGeoPlaceholder from 'assets/geo_world.svg';
import Button from 'components/Button';
import Card from 'components/Card';
import Dropdown from 'components/Dropdown';
import EmptyListPlaceholder from 'components/EmptyListPlaceholder';
import { Dots as DotsIcon } from 'components/Icons';
import PageInfo from 'components/PageInfo';
import Spin from 'components/Spin';
import Table from 'components/Table';
import { Title, Text, Link } from 'components/Typography';
import { IRule, IRuleset } from 'interfaces/IRuleset';
import { AI_DOCS_GEORULES } from 'lib/consts';
import { useMessage } from 'lib/hooks';
import DeleteRuleset from 'pages/Companies/GeoRulesets/Modals/DeleteRuleset';

import { generateColumns } from './columns';
import { CopyTag } from './CopyTag';
import CreateRulesetModal from './Modals/CreateRuleset';
import EditGlobalSettingsModal from './Modals/EditGlobalSettings';
import RegionalSettings from './Modals/EditRegionalRules';
import EditRulesetDetails from './Modals/EditRulesetDetails';
import { ScriptTag } from './ScriptTag';

const GeoRulesets = () => {
  const { companyId } = useParams() as { companyId: string };
  const { data: fetchedRulesets, mutate: mutateRulesets, isLoading } = useGetCustomerRuleSets(companyId);
  const { configurations } = getCompanySettings(companyId);
  const [isCreateRulesetOpen, setIsCreateRulesetOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string>('rules');
  const [rulesetToEdit, setRulesetToEdit] = useState<RuleSetOutDto | null>(null);
  const [isRegionalSettingsModalOpen, setIsRegionalSettingsModalOpen] = useState(false);
  const [isGlobalSettingOpen, setIsGlobalSettingOpen] = useState(false);
  const [isRulesetDetailsModalOpen, setIsRulesetDetailsModalOpen] = useState(false);
  const [isDeleteRulesetModalOpen, setIsDeleteRulesetModalOpen] = useState(false);
  const [focused, setFocused] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const message = useMessage();

  const generateItems = (ruleset: IRuleset) => [
    {
      label: 'Ruleset Details',
      key: 'details',
      'data-testid': 'menu-item:details',
      onClick: () => {
        setRulesetToEdit(ruleset);
        setIsRulesetDetailsModalOpen(true);
      },
    },
    {
      label: 'Global Settings',
      key: 'global',
      'data-testid': 'menu-item:global-settings',
      onClick: () => {
        setRulesetToEdit(ruleset);
        setIsGlobalSettingOpen(true);
      },
    },
    {
      label: 'Regional Settings',
      key: 'regional',
      'data-testid': 'menu-item:regional-settings',
      onClick: () => {
        setRulesetToEdit(ruleset);
        setIsRegionalSettingsModalOpen(true);
      },
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete',
      key: 'delete',
      'data-testid': 'menu-item:delete-ruleset',
      onClick: () => {
        setRulesetToEdit(ruleset);
        setIsDeleteRulesetModalOpen(true);
      },
      danger: true,
    },
  ];

  useEffect(() => {
    setFocused(location.hash.slice(1));
  }, [location.hash]);

  const rulesets = fetchedRulesets?.body || [];

  const updateUrl = (id: string) => {
    navigate(`#${id}`);
  };

  const onModalClose = async (closeCallback: () => void) => {
    mutateRulesets();
    closeCallback();
    setRulesetToEdit(null);
  };

  const onRuleSetDelete = async (ruleSet: RuleSetOutDto) => {
    try {
      await deleteRuleset(ruleSet.ruleSetId);
      await mutateRulesets();
      setIsDeleteRulesetModalOpen(false);
      message.success(`The Ruleset "${ruleSet.name || ruleSet.ruleSetId}" has been successfully deleted`);
    } catch (error) {
      message.error(error.response?.data.error?.msg);
    }
  };

  const updateRulesetList = async (rulesetId: string, rulesetList: RuleSetInDto) => {
    const r = (await updateRuleset(rulesetId, rulesetList)).body;
    setRulesetToEdit(r);
  };

  if (isLoading) {
    return <Spin />;
  }

  if (!rulesets || !configurations) {
    return null;
  }

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const tabList = [
    {
      key: 'rules',
      tab: 'Rules',
    },
    {
      key: 'implementation',
      tab: 'Implementation',
    },
  ];

  const tabsContentList = (ruleset: RuleSetOutDto) => ({
    rules: (
      <Table<IRule>
        style={{ maxWidth: '1000px' }}
        columns={generateColumns(configurations)}
        dataSource={[
          {
            ...ruleset.defaultRule,
            locations: ['All (Global)'],
            name: 'Default (Global)',
            settingsId: `Global Config [${ruleset.defaultRule.settingsId}]`,
          },
          ...ruleset.rules,
        ].map((rule: IRule) => ({ key: rule.settingsId, ...rule }))}
        autoHeight
        noBorder
      />
    ),
    implementation: (
      <>
        <ScriptTag ruleset={ruleset} settings={configurations} />
        <CopyTag
          tooltip="Include the Ruleset ID in your App to display your CMP configurations to users based on their location."
          name="Ruleset ID"
          link={ruleset.ruleSetId}
        />
      </>
    ),
  });

  return (
    <div>
      {isCreateRulesetOpen && (
        <CreateRulesetModal
          mutateRulesets={mutateRulesets}
          configurations={configurations}
          companyId={companyId}
          onClose={() => setIsCreateRulesetOpen(false)}
        />
      )}
      <PageInfo
        title={
          <>
            <Flex justify="space-between">
              <Title>Geolocation Rulesets</Title>
              {rulesets.length > 0 && (
                <Button
                  type="primary"
                  onClick={() => setIsCreateRulesetOpen(true)}
                  data-testid="button:add-ruleset-open"
                >
                  Add Ruleset
                </Button>
              )}
            </Flex>
          </>
        }
        description={
          <>
            Create and manage Geolocation Rulesets for an international CMP setup. Learn more in our{' '}
            <Link href={AI_DOCS_GEORULES} target="_blank" rel="noreferrer">
              documentation
            </Link>
            .
          </>
        }
      />
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        {rulesets.length > 0 ? (
          rulesets.map((ruleset) => {
            const isFocused = focused === ruleset.ruleSetId;
            return (
              <Card
                title={
                  <>
                    <Title ellipsis level={5}>
                      {ruleset.name}
                    </Title>
                    <Text ellipsis>{ruleset.description}</Text>
                  </>
                }
                tabList={tabList}
                key={ruleset.ruleSetId}
                activeTabKey={activeTabKey}
                onClick={() => updateUrl(ruleset.ruleSetId)}
                tabProps={{ size: 'small' }}
                onTabChange={onTabChange}
                styles={{ body: { padding: 0 }, header: { margin: 0 } }}
                focused={isFocused}
                extra={
                  <Dropdown items={generateItems(ruleset)} onOpenChange={() => updateUrl(ruleset.ruleSetId)}>
                    <a
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      data-testid={`div:rulesets-menu[${ruleset.ruleSetId}]`}
                    >
                      <DotsIcon />
                    </a>
                  </Dropdown>
                }
              >
                {/* // todo fix */}
                {tabsContentList(ruleset)[activeTabKey as 'rules' | 'implementation']}
              </Card>
            );
          })
        ) : (
          <EmptyListPlaceholder
            title="No ruleset created"
            description={
              <span>
                Creating a Geolocation Ruleset enables you to define for which region a certain configuration should be
                displayed. Each Geolocation Ruleset consists of a <b>Global Rule</b> and one or more{' '}
                <b>Regional Rules</b> (if any).
              </span>
            }
            image={emptyGeoPlaceholder}
            button={{ text: 'Create Ruleset', onClick: () => setIsCreateRulesetOpen(true) }}
          />
        )}
      </Space>

      {rulesetToEdit && isRegionalSettingsModalOpen && (
        <RegionalSettings
          onClose={() => onModalClose(() => setIsRegionalSettingsModalOpen(false))}
          onUpdate={updateRulesetList}
          ruleset={rulesetToEdit}
          configurations={configurations}
        />
      )}
      {rulesetToEdit && isGlobalSettingOpen && (
        <EditGlobalSettingsModal
          onClose={() => onModalClose(() => setIsGlobalSettingOpen(false))}
          onUpdate={updateRulesetList}
          configurations={configurations}
          ruleset={rulesetToEdit}
        />
      )}
      {rulesetToEdit && isRulesetDetailsModalOpen && (
        <EditRulesetDetails
          ruleset={rulesetToEdit}
          onClose={() => onModalClose(() => setIsRulesetDetailsModalOpen(false))}
          onUpdate={updateRulesetList}
        />
      )}
      {rulesetToEdit && isDeleteRulesetModalOpen && (
        <DeleteRuleset
          open={isDeleteRulesetModalOpen}
          onClose={() => onModalClose(() => setIsDeleteRulesetModalOpen(false))}
          onDelete={() => onRuleSetDelete(rulesetToEdit)}
        />
      )}
    </div>
  );
};

export default GeoRulesets;
