import { Tag as AntTag } from 'antd';
import styled, { css } from 'styled-components';

import { ITagProps } from '.';

const newStyles = css`
  color: white;
  background: ${(props) => props.theme.colors.green[100]};
  border-radius: 14px;
  padding: 3px 8px;

  & svg {
    margin-right: 6px;
  }
`;

export const StyledTag = styled(AntTag)<ITagProps>`
  font-weight: ${(props) => (props.$bolder ? 600 : props.$bold ? 500 : 400)};
  max-height: 32px;
  display: inline-flex;
  align-items: center;

  ${(props) => props.size === 'small' && 'padding: 1px 3px;'}

  ${(props) => props.size === 'normal' && 'padding: 2px 6px;'}

  ${(props) => props.size === 'medium' && 'padding: 6px 8px; line-height: 16px;'}

  ${(props) => props.size === 'middle' && 'padding: 7px; font-size: 14px;'}

  ${(props) => props.size === 'large' && 'padding: 8px 12px; font-size: 13px;  max-width: 76px;'}

  &.ant-tag-red {
    color: ${({ theme }) => theme.colors.red[50]};
    background-color: ${({ theme }) => theme.colors.red[8]};
  }

  &.ant-tag-green {
    color: ${({ theme }) => theme.colors.green[200]};
    background-color: ${({ theme }) => theme.colors.green[8]};
  }

  &.ant-tag-gold {
    color: ${({ theme }) => theme.colors.yellow[100]};
    background-color: ${({ theme }) => theme.colors.yellow[8]};
  }

  &.ant-tag-blue {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-blue-inverse {
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.primary}1a` : 'none')};
    background-color: ${({ theme }) => theme.colors.blue[8]};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-geekblue-inverse {
    border: none;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ant-tag-purple {
    color: ${({ theme }) => theme.colors.purple[100]};
  }

  &.ant-tag-default {
    color: ${({ theme }) => theme.colors.blue[300]};
    background: ${({ theme }) => theme.colors.gray[12]};
  }

  ${(props) => props.transparent && `color: ${props.theme.colors.gray[80]}; background: transparent;margin: 0;`}

  & > a + span {
    margin-left: 8px;
  }

  svg {
    max-width: 17px;
    max-height: 17px;
  }

  & .anticon {
    vertical-align: text-bottom;
  }

  ${(props) => props.$newStyle && newStyles};
`;
