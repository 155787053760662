import styled from 'styled-components';

export const Counter = styled.p`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Container = styled.div<{ isBigScreen: boolean }>`
  max-width: 90%;
  min-width: ${(props) => (!props.isBigScreen ? '450px' : '700px')};
`;
