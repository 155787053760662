import { reset } from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import { TrackingEvents } from 'interfaces/enums';
import { trackEvent } from 'lib/helpers/amplitude';

import { USER_AUTH0_PARAMETERS, ONBOARDING_TYPE, TRIAL_COOKIES } from '../consts';

export const useLogout = (redirectToLogout = false) => {
  const { logout, user = {} } = useAuth0();
  const userOnboarding = user[USER_AUTH0_PARAMETERS.uc_onboarding];

  return async () => {
    reset();

    let urlTrialParam = '';
    if (Cookies.get(TRIAL_COOKIES.TRIAL_USED)) {
      urlTrialParam = `?flow=${Cookies.get(TRIAL_COOKIES.TRIAL_USED)}`;
    } else if (userOnboarding === ONBOARDING_TYPE.DMA) {
      urlTrialParam = `?flow=${ONBOARDING_TYPE.DMA}`;
    } else if (userOnboarding === ONBOARDING_TYPE.STANDARD) {
      urlTrialParam = `?flow=${ONBOARDING_TYPE.STANDARD}`;
    }
    // TODO: Remove this once specific URL parameter for plan name is added
    else if (userOnboarding === ONBOARDING_TYPE.SUMMER_2024) {
      urlTrialParam = `?flow=${ONBOARDING_TYPE.SUMMER_2024}`;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: TrackingEvents.LOGOUT,
      },
    });
    trackEvent(TrackingEvents.LOGOUT);

    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}${redirectToLogout ? '/logout' : '/login'}${urlTrialParam}`,
      },
    });
  };
};
