import { TagProps } from 'antd';

import { Person as PersonIcon, Stars as StarsIcon } from 'components/Icons';

import { StyledTag } from './styled';

export interface ITagProps extends TagProps {
  $bolder?: boolean;
  $bold?: boolean;
  size?: 'small' | 'middle' | 'normal' | 'medium' | 'large';
  transparent?: boolean;
  $newStyle?: boolean;
}

const Tag = ({ children, $bold = true, size = 'small', ...props }: ITagProps) => {
  return (
    <StyledTag bordered={false} $bold={$bold} size={size} {...props}>
      {children}
    </StyledTag>
  );
};

const PermissionTag = (props: ITagProps) => <Tag icon={<PersonIcon />} {...props} />;
const NewTag = ({ children, ...props }: ITagProps) => (
  <Tag $newStyle {...props}>
    <StarsIcon />
    {children}
  </Tag>
);

export default Tag;

export { PermissionTag, NewTag };
