import { Form } from 'antd';
import { KeyedMutator } from 'swr';

import { createRuleset } from 'api/requests';
import { RuleSetOutDto, OkResponseDto } from 'api/requests/generated/generated.schemas';
import { StepModal } from 'components/Modal';
import ISetting from 'interfaces/ISetting';
import useMessage from 'lib/hooks/useMessage';

import Details from './Content/Details';
import GlobalSetting from './Content/GlobalSetting';

const { useForm, useWatch } = Form;

interface IProps {
  onClose: () => Promise<void> | void;
  companyId: string;
  configurations: ISetting[];
  mutateRulesets: KeyedMutator<OkResponseDto & { body: RuleSetOutDto[] }>;
}

const CreateRulesetModal = ({ onClose, companyId, configurations, mutateRulesets }: IProps) => {
  const [globalSettingForm] = useForm();
  const [detailsForm] = useForm();

  const settingsId = useWatch(['settingsId'], { form: globalSettingForm, preserve: true });
  const rulesetName = useWatch(['name'], detailsForm);
  const message = useMessage();

  const addRuleset = async () => {
    const details = detailsForm.getFieldsValue(true);
    const globalSetting = globalSettingForm.getFieldsValue(true);

    const data = {
      ...details,
      rules: [],
      defaultRule: globalSetting,
    };
    await createRuleset(data, { customerId: companyId });

    message.success('Ruleset  was created successfully');
    await mutateRulesets();
  };

  return (
    <StepModal
      open
      title="Create Ruleset"
      okText="Create Ruleset"
      width={770}
      bodyHeight={500}
      onClose={onClose}
      steps={[
        {
          content: <Details form={detailsForm} />,
          primaryButton: {
            disabled: !rulesetName,
          },
        },
        {
          content: <GlobalSetting form={globalSettingForm} configurations={configurations} />,
          handleNext: addRuleset,
          primaryButton: {
            disabled: !settingsId,
          },
        },
      ]}
    />
  );
};

export default CreateRulesetModal;
